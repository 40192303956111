import styled from "styled-components";
import {theme} from "../../../constants";

const Row = styled.tr`
  color: ${props => props.danger ? '#ff0000' : (props.success ? '#106c24' : '#000000')};
  &:nth-child(odd) {
    background-color: ${theme.colors.table.rowBackgroundColourOdd};
  }
  &:nth-child(even) {
    background-color: ${theme.colors.table.rowBackgroundColourEven};
  }
  &:hover {
    cursor: pointer;
    background-color: ${props =>
  props.isHighlightOnHover &&
  theme.colors.table.rowBackgroundColourHighlight};
  }
`;

export default Row;
