import React, { Component } from "react";
import styled from 'styled-components';
import { CaretLeft, CaretRight, User, Users } from "styled-icons/fa-solid";

import AppContent from "../shared/layout/AppContent";
import ContentContainer from "../shared/layout/ContentContainer";
import Column from "../shared/layout/Column";
import WidgetHeading from "../shared/dashboard/FrontpageWidgetHeading";
import ApertureHeading from "../shared/aperture/ApertureHeading";
import Button from "../shared/Button";

import Image from "../shared/Image";

import calendarimage from "../../assets/images/calendar.png";

const CalendarLabel = styled.div`
  margin-top: 5px;
  padding: 3px;
  cursor: pointer;
  
  &: hover {
    background-color: #ced61b;
  }
`;

class Calendar extends Component {
  render() {
    return (
      <AppContent>
        <ContentContainer>
          <Column width="25%">
            <WidgetHeading style={{marginTop:"20px"}}>
              My Team Calendar
            </WidgetHeading>
            <ContentContainer>
              <Column width="10%">
                <CalendarLabel><User size={16} /></CalendarLabel>
                <CalendarLabel><User size={16} /></CalendarLabel>
                <CalendarLabel><User size={16} /></CalendarLabel>
                <CalendarLabel><User size={16} /></CalendarLabel>
                <CalendarLabel><User size={16} /></CalendarLabel>
                <CalendarLabel><User size={16} /></CalendarLabel>
                <CalendarLabel><User size={16} /></CalendarLabel>
              </Column>
              <Column width="90%">
                <div style={{marginTop:"5px", padding:"3px", cursor: "pointer", backgroundColor:"#636a0c", color: "#ffffff"}}>
                  Me &sdot; <span style={{fontSize:"0.8em", color:"#ffffff"}}>ON LEAVE</span>
                </div>
                <CalendarLabel>Melissa Wilson</CalendarLabel>
                <CalendarLabel>Matt Johnson</CalendarLabel>
                <CalendarLabel>Robert Moore</CalendarLabel>
                <CalendarLabel>Amanda Jones</CalendarLabel>
                <CalendarLabel>Michael Miller &sdot; <span style={{fontSize:"0.8em", color:"#898989"}}>ON LEAVE</span></CalendarLabel>
                <CalendarLabel>Sarah Davis</CalendarLabel>
              </Column>
            </ContentContainer>
            <WidgetHeading style={{marginTop:"20px"}}>
              Group Calendars
            </WidgetHeading>
            <ContentContainer>
              <Column width="10%">
                <CalendarLabel><Users size={16} /></CalendarLabel>
                <CalendarLabel><Users size={16} /></CalendarLabel>
                <CalendarLabel><Users size={16} /></CalendarLabel>
                <CalendarLabel><Users size={16} /></CalendarLabel>
                <CalendarLabel><Users size={16} /></CalendarLabel>
                <CalendarLabel><Users size={16} /></CalendarLabel>
                <CalendarLabel style={{marginTop:"15px"}}><Users size={16} /></CalendarLabel>
                <CalendarLabel><Users size={16} /></CalendarLabel>
                <CalendarLabel><Users size={16} /></CalendarLabel>
                <CalendarLabel style={{marginTop:"15px"}}><Users size={16} /></CalendarLabel>
              </Column>
              <Column width="90%">
                <CalendarLabel>Sales</CalendarLabel>
                <CalendarLabel>Order Processing</CalendarLabel>
                <CalendarLabel>Marketing</CalendarLabel>
                <CalendarLabel>Logistics</CalendarLabel>
                <CalendarLabel>Finance</CalendarLabel>
                <CalendarLabel>IT</CalendarLabel>
                <CalendarLabel style={{marginTop:"15px"}}>Tiptree (All Staff)</CalendarLabel>
                <CalendarLabel>Watchfield (All Staff)</CalendarLabel>
                <CalendarLabel>Oxford (All Staff)</CalendarLabel>
                <CalendarLabel style={{marginTop:"15px"}}>All</CalendarLabel>
              </Column>
            </ContentContainer>
            <hr />
          </Column>
          <Column width="75%">
            <ContentContainer style={{margin:"20px 10px 15px 10px", alignItems: "center"}}>
              <ContentContainer>
                <Button>
                  <CaretLeft size={16} />
                </Button>
                <Button style={{marginLeft:"5px"}}>
                  <CaretRight size={16} />
                </Button>
              </ContentContainer>
              <div>
                <strong>APRIL</strong>
              </div>
              <ContentContainer>
                <Button>
                  Month
                </Button>
                <Button style={{marginLeft:"5px"}}>
                  Week
                </Button>
                <Button style={{marginLeft:"5px"}}>
                  Day
                </Button>
              </ContentContainer>
            </ContentContainer>
            <Image src={calendarimage} />
            <div style={{color:"red", textAlign:"center"}}>
              (This calendar is just a placeholder static image)
            </div>
          </Column>
        </ContentContainer>
      </AppContent>
    )
  }
};

export default Calendar;
