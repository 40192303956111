import React, { Component } from "react";
import styled from 'styled-components';

import AppContent from "./shared/layout/AppContent";
import Button from "./shared/Button";
import PageHeading from "./shared/PageHeading";
import FormControl from "./shared/form-controls/FormControl";
import FormInput from "./shared/form-controls/FormInput";

import pwgLogo from "../assets/icons/pwg-logo-256-256.png";
import twLogo from "../assets/icons/tw-logo-256-256.png";
import daleLogo from "../assets/icons/dale-logo-256-256.png";

const ReturnLabel = styled.div`
  position: absolute;
  top: 58px;
  left: 5px;
  width: 270px;
  padding: 20px 10px 20px 10px;
  color: red;
  font-family: 'Arial';
  font-size: 0.9em;
  background-color: #ffeeee;
  border: 1px solid red;
  cursor: pointer;
`;

const ReturnLabelCaret = styled.div`
  position: absolute;
  top: -6px;
  left: 38px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border: solid red;
  border-width: 1px 0 0 1px;
  background-color: #ffeeee;
  
  @media (min-width: 1400px) {
    right: 55px;
  }
`;

const RoleButton = styled(Button)`
  border: 1px solid white;
  border-radius: 15px;
  width: 200px;
  margin: 20px;
  padding: 25px;
  
  &: hover {
    color: #343434;
    background-color: #efefef;
  }
`;

const GotItButton = styled(Button)`
  margin-top: 15px;
  border: 1px solid red;
  color: red;
  background-color: #ffeeee;
  
  &: hover {
    background-color: #ff9999;
  }
`;

class LoginForm extends Component {
  state = {
    pwgUserName: "",
    pwgPassword: ""
  };

  hideLogoTooltip = () => {
    this.props.hideLogoTooltip();
  };

  handleInputChange = async event => {
    await this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.authenticate(this.state);
  };

  render() {
    return (
      <AppContent style={{padding:"5px 0px 20px 0px"}}>
        {this.props.isShowLogoTooltip
        &&
        <ReturnLabel onClick={this.hideLogoTooltip}>
          <ReturnLabelCaret/>
          <div>Click logo to return here at any time</div>
          <div style={{display:"flex", justifyContent:"center"}}>
            <GotItButton>OK, got it</GotItButton>
          </div>
        </ReturnLabel>
        }
        <PageHeading centre large>New User Interface demonstration</PageHeading>
        <hr />
        <div style={{display:"flex", justifyContent:"center", backgroundColor: "#efefef", borderRadius: "10px", marginTop: "20px"}}>
          <div style={{width:"800px", fontSize:"1.4em"}}>
            <ul style={{lineHeight:"1.4em"}}>
              <li>This is a mock-up for discussion.</li>
              <li>Buttons in the demo may not do anything.</li>
              <li>Do not expect real data.</li>
              <li>Click the logo at the top-left to return here at any time.</li>
            </ul>
          </div>
        </div>
        <div style={{textAlign:"center", fontSize:"1.4em", marginTop:"15px"}}>Choose a user role</div>
        <hr />
        <div style={{display:"flex", justifyContent:"center"}}>
          <RoleButton onClick={()=> this.props.automaticLogin('twDealer')}>
            <img src={twLogo} style={{width:"100px"}} /><br />Timber Windows Dealer
          </RoleButton>
          <RoleButton onClick={()=> this.props.automaticLogin('daleDealer')}>
            <img src={daleLogo} style={{width:"100px"}} /><br />Dale Dealer
          </RoleButton>
          <RoleButton onClick={()=> this.props.automaticLogin('sales')}>
            <img src={pwgLogo} style={{width:"100px"}} /><br />PWG Sales User
          </RoleButton>
          <RoleButton onClick={()=> this.props.automaticLogin('orderProcessing')}>
            <img src={pwgLogo} style={{width:"100px"}} /><br />PWG Order Processor
          </RoleButton>
        </div>
        <div style={{display:"flex", justifyContent:"center"}}>
          <RoleButton onClick={()=> this.props.automaticLogin('logistics')}>
            <img src={pwgLogo} style={{width:"100px"}} /><br />PWG Logistics User
          </RoleButton>
          <RoleButton onClick={()=> this.props.automaticLogin('serviceTeam')}>
            <img src={pwgLogo} style={{width:"100px"}} /><br />PWG Service Team User
          </RoleButton>
        </div>
        <div>
          {this.props.isShowLoginErrorMessage && (
            <p>
              Sorry your login details have not been recognized. Please try
              again.
            </p>
          )}
        </div>
        <div style={{display:"none"}}>
          <p>Please log in using your PWG control centre account</p>
          <div>
            <div style={{width:"400px"}}>
              <FormControl style={{marginBottom:"10px"}}>
                <label htmlFor="pwgUserName">
                  Username
                </label>
                <FormInput
                  type="text"
                  id="pwgUserName"
                  name={"pwgUserName"}
                  className={"pwgUserName"}
                  value={this.state.pwgUserName}
                  onChange={this.handleInputChange}
                  required
                />
              </FormControl>
              <FormControl>
                <label htmlFor="pwgPassword">
                  Password
                </label>
                <FormInput
                  type="password"
                  id="pwgPassword"
                  name={"pwgPassword"}
                  className={"pwgPassword"}
                  value={this.state.pwgPassword}
                  onChange={this.handleInputChange}
                />
              </FormControl>
            </div>
            <br />
            <div>
              <button
                style={{
                  marginRight: "198px",
                  padding: "5px 20px 5px 20px",
                  cursor: "pointer"
                }}
                type="button"
                onClick={this.handleSubmit}
                data-testid="loginButton"
                disabled={
                  this.state.pwgUserName !== "" &&
                  this.state.pwgPassword !== "" ? (
                    false
                  ) : (
                    "disabled"
                  )
                }
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </AppContent>
    );
  }
}

export default LoginForm;
