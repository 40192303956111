import styled from "styled-components";

const FormControl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
`;

export default FormControl;
