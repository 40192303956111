import React, { Component } from "react";
import { Phone, Envelope } from "styled-icons/fa-solid";

import AppContent from "../shared/layout/AppContent";
import ApertureHeading from "../shared/aperture/ApertureHeading";
import InlineForm from "../shared/form-controls/InlineForm";
import ContentContainer from "../shared/layout/ContentContainer";
import Column from "../shared/layout/Column";
import Card from "../shared/Card";
import CardHeading from "../shared/CardHeading";
import CardContent from "../shared/CardContent";
import Button from "../shared/Button";

import Table from "../shared/table/Table";
import Row from "../shared/table/Row";
import Cell from "../shared/table/Cell";
import HeaderCell from "../shared/table/HeaderCell";
import HeaderRow from "../shared/table/HeaderRow";

import pipelineimage from '../../assets/images/pipeline.png';
import monthlyordervaluesimage from '../../assets/images/aperture-monthly-order-values.png';

class ApertureCRM extends Component
{
  render() {
    return (
      <AppContent>
        <ApertureHeading centre>
          CRM Dashboard Mock-Up
        </ApertureHeading>
        <ContentContainer>
          <Column width="50%" firstColumn>
            <Card style={{width:"400px"}}>
              <CardHeading brand="TW">
                Team Calendar
              </CardHeading>
              <CardContent>
                <ApertureHeading centre>Your Agenda</ApertureHeading>
                  <p style={{color:"#c5651f",fontWeight:"bold", borderBottom:"1px solid #c5651f", paddingBottom:"7px"}}>Today</p>
                  <div>
                    <div style={{display: "inline",marginRight: "20px", marginTop:"5px"}}>10:00 </div> Meeting<br />
                    <div style={{display: "inline",marginRight: "20px", marginTop:"5px"}}>14:00 </div> Site visit<br />
                  </div>
                  <p style={{color:"#c5651f",fontWeight:"bold", borderBottom:"1px solid #c5651f", paddingBottom:"7px"}}>Tomorrow</p>
                  <div style={{marginBottom:"15px"}}>
                    <div style={{display: "inline",marginRight: "20px", marginTop:"5px"}}>10:00 </div> Meeting<br />
                    <div style={{display: "inline",marginRight: "20px", marginTop:"5px"}}>10:00 </div> Something else<br />
                  </div>
                <div style={{display:"flex", justifyContent: "flex-end", width:"100%", marginTop:"10px"}}>
                  <Button>Open Team Calendar</Button>
                </div>
                <hr />
                <ApertureHeading style={{marginTop:"25px"}}centre>Upcoming fittings</ApertureHeading>
                <p style={{color:"#c5651f",fontWeight:"bold", borderBottom:"1px solid #c5651f", paddingBottom:"7px"}}>Mon 15 Feb 2019</p>
                <div style={{marginBottom:"15px"}}>
                  <div style={{display: "inline",marginRight: "45px", marginTop:"5px"}}>AM </div> Gus Irwin<br />
                </div>
                <p style={{color:"#c5651f",fontWeight:"bold", borderBottom:"1px solid #c5651f", paddingBottom:"7px"}}>Tue 16 Feb 2019</p>
                <div style={{marginBottom:"15px"}}>
                  <div style={{display: "inline",marginRight: "20px", marginTop:"5px"}}>All Day </div> Ewan Hartman<br />
                </div>
                <p style={{color:"#c5651f",fontWeight:"bold", borderBottom:"1px solid #c5651f", paddingBottom:"7px"}}>Fri 19 Feb 2019</p>
                <div style={{marginBottom:"15px"}}>
                  <div style={{display: "inline",marginRight: "20px", marginTop:"5px"}}>All Day </div> Margaret Summers<br />
                </div>
                <div style={{display:"flex", justifyContent: "flex-end", width:"100%", marginTop:"10px"}}>
                  <Button>Open Fitting Schedule</Button>
                </div>
              </CardContent>
            </Card>
          </Column>
          <Column width="50%">
            <Card style={{minWidth:"500px"}}>
              <CardHeading brand="TW">
                Current activities and leads
              </CardHeading>
              <CardContent>
                <ApertureHeading centre>Activities</ApertureHeading>
                <Table>
                  <thead>
                  <HeaderRow>
                    <HeaderCell scope="col">Due</HeaderCell>
                    <HeaderCell scope="col">Customer</HeaderCell>
                    <HeaderCell scope="col">Action</HeaderCell>
                  </HeaderRow>
                  </thead>
                  <tbody>
                  <Row>
                    <Cell>Tue 8 Jan</Cell>
                    <Cell>Molly Kramer</Cell>
                    <Cell><Phone size={16} style={{color: "#7a8e8d", marginRight:"10px"}}/> Call - issue with door</Cell>
                  </Row>
                  <Row>
                    <Cell>Tue 8 Jan</Cell>
                    <Cell>Sara Donovan</Cell>
                    <Cell><Phone size={16} style={{color: "#7a8e8d", marginRight:"10px"}}/> Call about deposit payment</Cell>
                  </Row>
                  <Row>
                    <Cell>Tue 8 Jan</Cell>
                    <Cell>Ian Stanley</Cell>
                    <Cell><Phone size={16} style={{color: "#7a8e8d", marginRight:"10px"}}/> Call back</Cell>
                  </Row>
                  <Row>
                    <Cell>Tue 8 Jan</Cell>
                    <Cell>Anthony Cook</Cell>
                    <Cell><Envelope size={16} style={{color: "#7a8e8d", marginRight:"10px"}}/> Post brochure</Cell>
                  </Row>
                  </tbody>
                </Table>
                <div style={{display:"flex", justifyContent: "flex-end", width:"100%", marginTop:"10px"}}>
                  <Button>Show all activities</Button>
                </div>

                <ApertureHeading centre>New Leads</ApertureHeading>
                <Table>
                  <thead>
                  <HeaderRow>
                    <HeaderCell scope="col">Date</HeaderCell>
                    <HeaderCell scope="col">Customer</HeaderCell>
                    <HeaderCell scope="col">Source</HeaderCell>
                  </HeaderRow>
                  </thead>
                  <tbody>
                  <Row>
                    <Cell>18 Dec 18</Cell>
                    <Cell>Sara Donovan</Cell>
                    <Cell>TW Enquiry</Cell>
                  </Row>
                  </tbody>
                </Table>
                <div style={{display:"flex", justifyContent: "flex-end", width:"100%", marginTop:"10px"}}>
                  <Button>Show all leads</Button>
                </div>

                <ApertureHeading centre>New Messages</ApertureHeading>
                <Table>
                  <thead>
                  <HeaderRow>
                    <HeaderCell scope="col">Date</HeaderCell>
                    <HeaderCell scope="col">Person</HeaderCell>
                    <HeaderCell scope="col">Subject</HeaderCell>
                  </HeaderRow>
                  </thead>
                  <tbody>
                  <Row>
                    <Cell>01 Jan 19 16:23</Cell>
                    <Cell>Ian Stanley</Cell>
                    <Cell>Door handle colour</Cell>
                  </Row>
                  <Row>
                    <Cell>31 Dec 18 12:03</Cell>
                    <Cell>Sara Donovan</Cell>
                    <Cell>Deposit payment</Cell>
                  </Row>
                  <Row>
                    <Cell>26 Dec 18 19:39</Cell>
                    <Cell>Sara Donovan</Cell>
                    <Cell>Deposit payment</Cell>
                  </Row>
                  </tbody>
                </Table>
                <div style={{display:"flex", justifyContent: "flex-end", width:"100%", marginTop:"10px"}}>
                  <Button>Show all messages</Button>
                </div>
              </CardContent>

            </Card>
          </Column>
          <Column width="50%">
            <Card style={{minWidth:"350px"}}>
              <CardHeading brand="TW">
                Statistics
              </CardHeading>
              <CardContent>
                <ApertureHeading centre>Sales</ApertureHeading>
                <InlineForm>
                  <div>Monthly total</div><div style={{fontSize:"2em"}}>45,000</div>
                </InlineForm>
                <InlineForm>
                  <div>Average order value</div><div style={{fontSize:"2em"}}>£8,000</div>
                </InlineForm>
                <InlineForm>
                  <div>Conversion rate (last 12 months)</div><div style={{fontSize:"2em"}}>33%</div>
                </InlineForm>
                <InlineForm>
                  <div>Leads quoted (last 6 months)</div><div style={{fontSize:"2em"}}>75%</div>
                </InlineForm>
                <InlineForm>
                  <div>Self-generation rate (last 12 months)</div><div style={{fontSize:"2em"}}>1:5</div>
                </InlineForm>
                <hr />
                <ApertureHeading style={{marginTop:"25px"}} centre>Monthly Sales Totals</ApertureHeading>
                <div style={{padding:"0px", textAlign:"center"}}>
                  <img src={monthlyordervaluesimage} style={{width:"350px"}}/>
                </div>
                <hr />
                <ApertureHeading style={{marginTop:"25px"}} centre>Conversion Summary</ApertureHeading>
                <div style={{padding:"0px", textAlign:"center"}}>
                  <img src={pipelineimage} style={{width:"300px"}}/>
                </div>
                <div style={{display:"flex", justifyContent: "flex-end", width:"100%", marginTop:"10px"}}>
                  <Button>View all statistics</Button>
                </div>
              </CardContent>

            </Card>
          </Column>
        </ContentContainer>
      </AppContent>
    )
  }
};

export default ApertureCRM;
