import styled from "styled-components";
import { theme } from "../../constants";

const Bar = styled.div`
  background-color: ${props => props.emphasis ? theme.colors.pwg : theme.colors.gray2};
  height: 20px;
  width: ${props => (Number(props.number) * 4) + 'px'};
  margin-right: 10px;
`;

export default Bar;
