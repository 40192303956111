import styled from "styled-components";

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 0.7em;
  margin-top: 10px;
`;

export default Table;
