import styled from "styled-components";
import { theme } from "../../constants";

const CardImage = styled.img`
  width: 100%;
  margin: 0px;
  padding: 0px;
  border-bottom: ${props => props.border ? `1px solid ${theme.colors.gray2}` : 'none'};
`;

export default CardImage;
