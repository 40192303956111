import React, { Component } from "react";
import styled from 'styled-components';
import { CalendarAlt, Gamepad, TruckMoving, Phone, PoundSign, Desktop, Industry, Users, Cog } from "styled-icons/fa-solid";

import { theme } from "../../constants";
import pwgTree from "../../assets/icons/pwg-tree-logo-90-82.png";

const AppTileContainer = styled.div`
  font-family: ${theme.fonts.dashboard};
  font-size: 0.9em;
  display: flex;
  justify-content: space-between;
  align-items: top;
  padding-top: 18px;
  background-color: ${theme.colors.pwg}; 
  color: white;
`;

const AppTile = styled.div`
  display: inline-block;
  margin-right: 20px;
  width: 200px;
  text-align: center;
  padding-bottom: 10px;
  &:hover {
    cursor: pointer;
    color: ${theme.colors.gray2};
  }
`;

const AppIcon = styled.div`
  height: 60px;
`;

const AppTitle = styled.div`
  margin-top: 0px;
`;

class AppToolbar extends Component {
  openApp = url => {
    window.location.href = url;
  };
  render() {
    let smallerIconSize = 28;
    let largerIconSize = smallerIconSize * 1.14;

    return (
      <div>
        <AppTileContainer>
          <AppTile>
            <AppIcon><Phone size={largerIconSize}/></AppIcon>
            <AppTitle>Sales</AppTitle>
          </AppTile>
          <AppTile>
            <AppIcon><Industry size={smallerIconSize}/></AppIcon>
            <AppTitle>Order Processing</AppTitle>
          </AppTile>
          <AppTile>
            <AppIcon><Gamepad size={largerIconSize} /></AppIcon>
            <AppTitle>Control Centre</AppTitle>
          </AppTile>
          <AppTile>
            <AppIcon><PoundSign size={smallerIconSize} /></AppIcon>
            <AppTitle>Finance Reports</AppTitle>
          </AppTile>
          <AppTile>
            <AppIcon><TruckMoving size={largerIconSize}/></AppIcon>
            <AppTitle>Logistics</AppTitle>
          </AppTile>
          <AppTile>
            <AppIcon><Users size={smallerIconSize}/></AppIcon>
            <AppTitle>Service Requests</AppTitle>
          </AppTile>
          <AppTile>
            <AppIcon><Industry size={smallerIconSize}/></AppIcon>
            <AppTitle>Supplier Centre</AppTitle>
          </AppTile>
          <AppTile>
            <AppIcon><CalendarAlt size={smallerIconSize} /></AppIcon>
            <AppTitle>Leave Calendar</AppTitle>
          </AppTile>
          <AppTile>
            <AppIcon><Desktop size={smallerIconSize} /></AppIcon>
            <AppTitle>IT Projects</AppTitle>
          </AppTile>
          <AppTile>
            <AppIcon><Cog size={smallerIconSize}/></AppIcon>
            <AppTitle>Aperture Admin</AppTitle>
          </AppTile>
        </AppTileContainer>
      </div>
    )
  }
};

export default AppToolbar;



