import styled from "styled-components";
import {theme} from "../../../constants";

const HeaderContainer = styled.div`
  padding: 0px 25px 5px 25px;
  min-height: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.brand === 'TW' ? theme.colors.tw : (props.brand === 'PWG' && theme.colors.dashboardTitle)};
  border-bottom: 1px solid  ${props => props.brand === 'TW' ? theme.colors.tw : (props.brand === 'PWG' && theme.colors.pwg)};
  
  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
`;

export default HeaderContainer;
