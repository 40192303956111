import styled from "styled-components";
import { theme } from "../../../constants";

const Panel = styled.div`
  background-color: ${theme.colors.veryLightGrey};
  padding: 10px;
  border-radius: 10px;
`;

export default Panel;
