import styled from "styled-components";

const Select = styled.select`
  width: ${props => props.width ? props.width : "200px"};
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #dedede;
`;

export default Select;
