import React, { Component } from "react";

import Table from "../shared/table/Table";
import Row from "../shared/table/Row";
import Cell from "../shared/table/Cell";
import HeaderCell from "../shared/table/HeaderCell";
import HeaderRow from "../shared/table/HeaderRow";

class LeadsTable extends Component {
  render() {
    return (
      <Table>
        <thead>
        <HeaderRow>
          <HeaderCell />
          <HeaderCell scope="col">Date</HeaderCell>
          <HeaderCell scope="col">Customer Name</HeaderCell>
          <HeaderCell scope="col">Postcode</HeaderCell>
          <HeaderCell scope="col">Telephone</HeaderCell>
          <HeaderCell scope="col">Email</HeaderCell>
          <HeaderCell scope="col">Source</HeaderCell>
          <HeaderCell scope="col">Listed</HeaderCell>
          <HeaderCell scope="col">Showroom</HeaderCell>
          <HeaderCell scope="col">Salesperson</HeaderCell>
          <HeaderCell scope="col">Status</HeaderCell>
        </HeaderRow>
        </thead>
        <tbody>
        <Row>
          <Cell><input type="checkbox" /></Cell>
          <Cell>Tue 18 Dec 18 09:11</Cell>
          <Cell>Sara Donovan</Cell>
          <Cell>RG7 4PE</Cell>
          <Cell>01608 555123</Cell>
          <Cell>sara.donovan@iamacustomer.com</Cell>
          <Cell>TW Enquiry</Cell>
          <Cell>No</Cell>
          <Cell>Cirencester</Cell>
          <Cell>Jenson Interceptor</Cell>
          <Cell>Progressed</Cell>
        </Row>
        </tbody>
      </Table>
    )
  }
};

export default LeadsTable;
