import styled from "styled-components";
import { theme } from "../../constants";

const Image = styled.img`
  margin: 0px;
  width: 100%;
  border: ${props => props.border ? 'border 1px solid ' + theme.colors.gray2 : 'none'};
`;

export default Image;
