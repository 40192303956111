import React, { Component } from "react";
import { Check, ExclamationTriangle, Plus } from "styled-icons/fa-solid";

import AppContent from "../shared/layout/AppContent";
import ApertureHeading from "../shared/aperture/ApertureHeading";
import Button from "../shared/Button";
import Table from "../shared/table/Table";
import Row from "../shared/table/Row";
import Cell from "../shared/table/Cell";
import HeaderCell from "../shared/table/HeaderCell";
import HeaderRow from "../shared/table/HeaderRow";
import Alert from "../shared/Alert";

class ApertureTeam extends Component {
  render() {
    return (
      <AppContent>
        <Alert style={{marginTop:"10px"}}>
          <p>
            Currently on leave: Rosie Lambert (until 14 Jan 2019)
          </p>
        </Alert>
        <ApertureHeading centre>
          Sales Team
        </ApertureHeading>
        <div style={{display:"flex", justifyContent:"flex-start"}}>
          <Button style={{marginRight:"20px", width: "200px"}}>
            <Plus size={16} /> Add Team Member
          </Button>
        </div>

        <Table>
          <thead>
          <HeaderRow>
            <HeaderCell scope="col">Name</HeaderCell>
            <HeaderCell scope="col">Mobile</HeaderCell>
            <HeaderCell scope="col">Job Title</HeaderCell>
            <HeaderCell scope="col">Orders</HeaderCell>
            <HeaderCell scope="col">Commissions</HeaderCell>
            <HeaderCell scope="col">Status</HeaderCell>
            <HeaderCell />
          </HeaderRow>
          </thead>
          <tbody>
          <Row>
            <Cell>Caroline Merritt</Cell>
            <Cell>07123 555123</Cell>
            <Cell>Surveyor</Cell>
            <Cell>£12,500</Cell>
            <Cell>£150</Cell>
            <Cell>
              <span style={{color:"green"}}><Check size={16} /> Account Enabled</span>
            </Cell>
            <Cell>
              <Button>Manage</Button>
            </Cell>
          </Row>
          <Row>
            <Cell>Rosie Lambert</Cell>
            <Cell>07321 555123</Cell>
            <Cell>Sales Consultant</Cell>
            <Cell>£12,500</Cell>
            <Cell>£150</Cell>
            <Cell>
              <span style={{color:"green"}}><Check size={16} /> Account Enabled</span>
            </Cell>
            <Cell>
              <Button>Manage</Button>
            </Cell>
          </Row>
          <Row>
            <Cell>Declan Torres</Cell>
            <Cell>07321 555123</Cell>
            <Cell>Sales Consultant</Cell>
            <Cell>£12,500</Cell>
            <Cell>£150</Cell>
            <Cell>
              <span style={{color:"red"}}><ExclamationTriangle size={16} /> Account Disabled</span>
            </Cell>
            <Cell>
              <Button>Manage</Button>
            </Cell>
          </Row>
          </tbody>
        </Table>

        <ApertureHeading centre>
          Fitting Team
        </ApertureHeading>

        <div style={{display:"flex", justifyContent:"flex-start"}}>
          <Button style={{marginRight:"20px", width: "200px"}}>
            <Plus size={16} /> Add Team Member
          </Button>
        </div>

        <Table>
          <thead>
          <HeaderRow>
            <HeaderCell scope="col">Name</HeaderCell>
            <HeaderCell scope="col">Mobile</HeaderCell>
            <HeaderCell scope="col">Job Title</HeaderCell>
            <HeaderCell scope="col">Status</HeaderCell>
            <HeaderCell />
          </HeaderRow>
          </thead>
          <tbody>
          <Row>
            <Cell>Frazer Webb</Cell>
            <Cell>07123 555123</Cell>
            <Cell>Fitter</Cell>
            <Cell>
              <span style={{color:"green"}}><Check size={16} /> Account Enabled</span>
            </Cell>
            <Cell>
              <Button>Manage</Button>
            </Cell>
          </Row>
          <Row>
            <Cell>Abdul Abbott</Cell>
            <Cell>07321 555123</Cell>
            <Cell>Fitter</Cell>
            <Cell>
              <span style={{color:"green"}}><Check size={16} /> Account Enabled</span>
            </Cell>
            <Cell>
              <Button>Manage</Button>
            </Cell>
          </Row>
          <Row>
            <Cell>Holly Wilson</Cell>
            <Cell>07321 555123</Cell>
            <Cell>Fitter</Cell>
            <Cell>
              <span style={{color:"green"}}><Check size={16} /> Account Enabled</span>
            </Cell>
            <Cell>
              <Button>Manage</Button>
            </Cell>
          </Row>
          </tbody>
        </Table>
      </AppContent>
    )
  }
};

export default ApertureTeam;
