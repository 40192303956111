import styled from "styled-components";

const InfoContainer = styled.div`
  width: 450px;
  alignItems: center;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
`;

export default InfoContainer;
