import React, { Component } from "react";
import Link from "react-router-dom/es/Link";

import ContentContainer from "./shared/layout/ContentContainer";
import Column from "./shared/layout/Column";
import Card from "./shared/Card";
import CardHeading from "./shared/CardHeading";
import CardImage from "./shared/CardImage";
import CardContent from "./shared/CardContent";
import ButtonLink from "./shared/ButtonLink";

import DashboardHeading from "./shared/dashboard/DashboardHeading";

import mapsimage from "../assets/images/maps.png";
import mwimage from "../assets/images/mw.png";
import authimage from "../assets/images/auth.png";
import lmimage from "../assets/images/lm.png";
import pdfimage from "../assets/images/pdf.png";
import signatureimage from "../assets/images/signature.jpg";
import dashboardimage from "../assets/images/dashboard.png";
import uirefreshimage from "../assets/images/ui-refresh.png";
import styled from "styled-components";
import {theme} from "../constants";

// @Cleanup - we will need a shared component which can link to both internal and external sites
const ExternalLinkButton = styled.a`
  padding: 4px 25px 4px 25px;
  border: 1px solid ${theme.colors.border};
  border-radius: 4px;
  text-align: center;
  width: ${props => props.width ? props.width : 'auto'};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.brand === 'TW' ? theme.colors.tw : theme.colors.dashboardTitle};
    color: white;
  }
`;

class AppSummary extends Component {
  render() {
    return (
      <div>
        <DashboardHeading centre large>PROJECTS</DashboardHeading>
        <ContentContainer brand="PWG">
          <Column firstColumn maxWidth="50%">
            <Card>
              <CardHeading brand="PWG">
                Mumford Dashboard
              </CardHeading>
              <CardImage src={mwimage} border />
              <CardContent>
                This is a new feature on the dashboard for tracking key data for Mumford & Wood.<br />
              </CardContent>
              <div style={{display:"flex", justifyContent: "center", padding:"10px"}}>
                <ButtonLink to="/mumforddashboard">Details</ButtonLink>
              </div>

            </Card>
          </Column>
          <Column maxWidth="50%">
            <Card>
              <CardHeading brand="PWG">
                Maps
              </CardHeading>
              <CardImage src={mapsimage} border />
              <CardContent>
                Dealer owners can view a map showing where enquiry and order addresses are located.<br />
              </CardContent>
              <div style={{display:"flex", justifyContent: "center", padding:"10px"}}>
                <ExternalLinkButton href="https://aperture.timberwindows.com">Log in (dealer admin login required)</ExternalLinkButton>
              </div>

            </Card>
          </Column>
        </ContentContainer>

        {/*<DashboardHeading centre large style={{marginTop:"20px"}}>PROJECTS IN DEVELOPMENT</DashboardHeading>*/}

        <ContentContainer brand="PWG">
          <Column maxWidth="33%" firstColumn>
            <Card>
              <CardHeading brand="PWG">
                New Quote Documentation
              </CardHeading>
              <CardImage src={pdfimage} border />
              <CardContent>
                A project to improve the standard quote documentation generated by Aperture to bring it into line with the graphic style used in the latest Choices brochure.
              </CardContent>
            </Card>
          </Column>
          <Column maxWidth="33%">
            <Card>
              <CardHeading brand="PWG">
                Unified sign-on / authentication
              </CardHeading>
              <CardImage src={authimage} border />
              <CardContent>
                A project to integrate login and authentication for all PWG web applications, and to provide single user accounts for all users.
              </CardContent>
            </Card>
          </Column>
          <Column maxWidth="33%">
            <Card>
              <CardHeading brand="PWG">
                Digital signing
              </CardHeading>
              <CardImage src={signatureimage} border />
              <CardContent>
                A project to introduce digital signing of quote documentation by customers.<br />
                <br />
              </CardContent>
            </Card>
          </Column>
        </ContentContainer>
        <ContentContainer brand="PWG" style={{marginTop:"20px", marginBottom: "20px"}}>
          <Column maxWidth="33%" firstColumn>
            <Card>
              <CardHeading brand="PWG">
                Leave management app
              </CardHeading>
              <CardImage src={lmimage} border />
              <CardContent>
                An internal app to process holiday and sickness forms.
              </CardContent>
              <div style={{display:"flex", justifyContent: "center", padding:"10px"}}>
                <ExternalLinkButton href="https://lm.pwg-apps.co.uk/">Open beta app (login required)</ExternalLinkButton>
              </div>
            </Card>
          </Column>
          <Column maxWidth="33%">
            <Card>
              <CardHeading brand="PWG">
                New dealer dashboard
              </CardHeading>
              <CardImage src={dashboardimage} border />
              <CardContent>
                A dashboard for dealer owners showing key statistics such as time to sales, average sales values and conversion rates.
              </CardContent>
            </Card>
          </Column>
          <Column maxWidth="33%">
            <Card>
              <CardHeading brand="PWG">
                Aperture style refresh
              </CardHeading>
              <CardImage src={uirefreshimage} border />
              <CardContent>
                A project to upgrade the look, feel and behaviour of Aperture to meet modern standards.
              </CardContent>
            </Card>
          </Column>
        </ContentContainer>
      </div>
    )
  }
};

export default AppSummary;



