import styled from "styled-components";

const FormInput = styled.input`
  width: ${props => props.width ? props.width : "200px"};
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #dedede;
`;

export default FormInput;
