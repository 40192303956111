import styled from "styled-components";

const Column = styled.div`
  padding: 0px;
  padding-right: 25px;
  padding-left: ${props => props.firstColumn && '25px'};
  width: ${props => props.width ? props.width : "100%"}

  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
`;

export default Column;
