import React,  { Component } from "react";
import styled from "styled-components";
import {theme} from "../../../constants";

import pwgLogo from "../../../assets/icons/pwg-tree-logo-90-82.png";
import calendarAlt from "../../../assets/icons/calendarAlt.png";
import cog from "../../../assets/icons/cog.png";
import desktop from "../../../assets/icons/desktop.png";
import factory from "../../../assets/icons/factory.png";
import poundSign from "../../../assets/icons/poundSign.png";
import truck from "../../../assets/icons/truck.png";
import users from "../../../assets/icons/users.png";

const AppMenu = styled.div`
  font-family: ${theme.fonts.dashboard};
  position: absolute;
  top: 50px;
  right: 12px;
  width: 300px;
  height: auto;
  background-color: white;
  padding: 10px;
  border: 1px solid ${props => props.brand === 'TW' ? theme.colors.tw : (props.brand === 'PWG' && theme.colors.dashboardTitle)};
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  z-index: 100;
`;

const AppMenuCaret = styled.div`
  position: absolute;
  top: -6px;
  right: 55px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border: solid ${props => props.brand === 'TW' ? theme.colors.tw : (props.brand === 'PWG' && theme.colors.dashboardTitle)};
  border-width: 1px 0 0 1px;
  background-color: white;
  
  @media (min-width: 1400px) {
    right: 55px;
  }
`;

const AppMenuTitle = styled.div`
  width: 100%;
  display: block;
  text-align: center;
  padding-bottom: 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${props => props.brand === 'TW' ? theme.colors.tw : (props.brand === 'PWG' && theme.colors.dashboardTitle)};
  font-size: 0.8em;
  font-weight: bold;
`;

const AppMenuCloseButton = styled.div`
  position: absolute;
  top: 7px;
  right: 20px;
  width: 10px;
  height: 10px;
  cursor: pointer;
`;

const AppMenuItemContainer = styled.div`
  font-family: ${theme.fonts.helvetica};
  font-size: 0.8em;
  color: ${props => props.brand === 'TW' ? theme.colors.tw : (props.brand === 'PWG' && theme.colors.dashboardTitle)};
  text-align: center;
  width: 82px;
  height: 82px;
  cursor: pointer;
  padding; 10px;
  text-decoration: none !important;
  
  &:hover {
    opacity: 0.5;
    color: ${theme.colors.gray2}; 
  }
`;

class HeaderPopUpPanel extends Component {

  componentDidMount = () => {
    window.document.body.addEventListener('click', this.props.hidePopups, {capture: false});
  };

  componentWillUnmount = () => {
    window.document.body.removeEventListener('click', this.props.hidePopups, {capture: false});
  };

  render() {
      let apps = [];
      if (this.props.isApps)
      {
        apps = [
          {
            name: "Homepage",
            imageData: pwgLogo,
            url: "/"
          },
          {
            name: "Control Centre",
            imageData: pwgLogo,
            url: "/"
          },
          {
            name: "Logistics",
            imageData: truck,
            url: "/"
          },
          {
            name: "Finance",
            imageData: poundSign,
            url: "/"
          },
          {
            name: "Service Requests",
            imageData: users,
            url: "/"
          },
          {
            name: "Supplier Centre",
            imageData: factory,
            url: "/"
          },
          {
            name: "Leave Calendar",
            imageData: calendarAlt,
            url: ""
          },
          {
            name: "IT Projects",
            imageData: desktop,
            url: "/pwgApps"
          },
          {
            name: "Aperture Admin",
            imageData: cog,
            url: "/"
          }
        ];
      }
      return (
          <AppMenu style={{right:this.props.rightOffset + "px"}}>
              <AppMenuTitle>My PWG Apps</AppMenuTitle>
              {this.props.isApps && <p>(Example icons only - these buttons do not link anywhere)</p>}
              {apps.map(item => {
                  return (
                    <AppMenuItemContainer key={item.name}>
                        <img src={item.imageData} alt={item.name} style={{height:"48px"}}/><br/>
                        {item.name}
                    </AppMenuItemContainer>
                  );
              })}
              {this.props.children}
              <AppMenuCloseButton onClick={this.props.toggleVisibility}>
                  &times;
              </AppMenuCloseButton>
              <AppMenuCaret/>
          </AppMenu>
      );
  }
}

export default HeaderPopUpPanel;
