import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import HeaderPopUpPanel from "./HeaderPopUpPanel";
import Button from "../Button";
import ButtonLink from "../ButtonLink";
import HeaderButtonLink from "./HeaderButtonLink";
import HeaderButtonContainer from "./HeaderButtonContainer";
import UserDetailsNameLabel from "./UserDetailsNameLabel";
import HeaderContainer from "./HeaderContainer";
import HeaderMenuIcon from "./HeaderMenuIcon";

import pwgLogo from "../../../assets/icons/pwg-logo-256-256.png";
import twLogo from "../../../assets/icons/tw-logo-256-256.png";
import daleLogo from "../../../assets/icons/dale-logo-256-256.png";

import { Th } from "styled-icons/fa-solid";
import { User } from "styled-icons/fa-solid/User";

class Header extends Component {
  state = {
    isAppMenuShown: false,
    isUserPanelShown: false,
    isLoggingOut: false
  };

  getBrandIcon = brand => {
    if (brand === 'PWG')
      return pwgLogo;
    else if (brand === 'TW')
      return twLogo;
    else if (brand === 'Dale')
      return daleLogo;
  };

  logout = async () => {
    this.props.logout();
    // await this.setState({
    //   isAppMenuShown: false,
    //   isUserPanelShown: false
    // });
    // this.setState({isLoggingOut: true});
  };

  hidePopups = async e => {
    if (e.target.dataset.action === "logout") this.logout();
    await this.setState({
      isAppMenuShown: false,
      isUserPanelShown: false
    });
  };

  toggleAppMenu = async e => {
    await this.setState({
      isAppMenuShown: !this.state.isAppMenuShown,
      isUserPanelShown: false
    });
  };

  toggleUserPanel = async e => {
    await this.setState({
      isAppMenuShown: false,
      isUserPanelShown: !this.state.isUserPanelShown
    });
  };

  render() {
    return (
      <HeaderContainer brand={this.props.brand}>
        {this.state.isLoggingOut && <Redirect to='/' />}
        <HeaderButtonContainer brand={this.props.brand} style={{cursor:"pointer"}}>
          <img
            onClick={this.logout}
            src={this.getBrandIcon(this.props.brand)}
            alt={"logo"}
            style={{height:"48px"}}
          />
          {this.props.links.map(link => {
            return <HeaderButtonLink key={link.to} brand={this.props.brand} to={link.to}>
              {link.name}
            </HeaderButtonLink>
          })}
        </HeaderButtonContainer>

        <HeaderButtonContainer>
          {this.props.children}
          <HeaderMenuIcon onClick={this.toggleAppMenu}>
            <Th size={16} style={{ marginTop: "7px" }} />
          </HeaderMenuIcon>
          <HeaderMenuIcon onClick={this.toggleUserPanel}>
            {this.props.isAuthenticated ? (
              <User size={16} style={{ marginTop: "7px" }} />
            ) : (
              <User
                size={16}
                style={{ marginTop: "7px", color: "#cdcdcd" }}
              />
            )}
          </HeaderMenuIcon>
        </HeaderButtonContainer>
        {this.state.isAppMenuShown && (
          <HeaderPopUpPanel
            title="Demo pages"
            brand="TW"
            isApps={true}
            rightOffset={12}
            hidePopups={this.hidePopups}
          />
        )}

        {this.state.isUserPanelShown && (
          <HeaderPopUpPanel
            title="User Details"
            isApps={false}
            rightOffset={-22}
            hidePopups={this.hidePopups}
          >
            {this.props.isAuthenticated ? (
              <div style={{ width: "100%" }}>
                <UserDetailsNameLabel>
                  My Name
                </UserDetailsNameLabel>

                <p>User ID 100</p>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button brand={this.props.brand}>
                    My Account
                  </Button>
                  <ButtonLink brand={this.props.brand} to="/">
                    Log out
                  </ButtonLink>
                </div>
              </div>
            ) : (
              <p>
                You are not logged in.<br /><br />
                Click one of the links below.
              </p>
            )}
          </HeaderPopUpPanel>
        )}
      </HeaderContainer>
    );
  }
}

export default Header;
