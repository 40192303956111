import styled from "styled-components";
import {theme} from "../../../constants";

const HeaderMenuIcon = styled.div`
  text-align: center;
  color: ${props => props.brand === 'TW' ? theme.colors.tw : (props.brand === 'PWG' && theme.colors.dashboardTitle)};
  height: 28px;
  width: 28px;
  margin-left: 7px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export default HeaderMenuIcon;
