import styled from "styled-components";
import { theme } from "../../../constants";
import FrontpageWidgetHeading from "./FrontpageWidgetHeading";

const FrontpageWidgetSubHeading = styled(FrontpageWidgetHeading)`
  font-size: 1em;
  font-weight: bold;
  color: ${theme.colors.mediumGrey};
  text-align: ${props => props.centre ? 'center' : 'justify'}
  width: '100%';
  border-bottom: 1px solid ${theme.colors.mediumGrey};
  padding-botton: 4px;
  letter-spacing: normal;
`;

export default FrontpageWidgetSubHeading;
