import styled from "styled-components";

const InlineForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 0.9em;
`;

export default InlineForm;
