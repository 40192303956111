import styled from "styled-components";
import { theme } from "../../../constants";

const AppContainer = styled.div`
  margin: 0px;
  position: absolute;
  
  font-family: ${theme.fonts.body};
  font-size: 1em;
  text-align: justify;
  width: 100%;

  @media (min-width: 1400px) {
    width: 1400px;
    margin: 0px;
  }
`;

export default AppContainer;
