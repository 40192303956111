import styled from "styled-components";
import { theme } from "../../constants";

const Button = styled.div`
  color: ${props => props.brand === 'TW' ? theme.colors.tw : (props.brand === 'Dale' ? theme.colors.dale : '#000000')};
  padding: 4px 25px 4px 25px;
  border: 1px solid ${theme.colors.border};
  border-radius: 4px;
  text-align: center;
  width: ${props => props.width ? props.width : 'auto'}
  cursor: pointer;
  &:hover {
    background-color: ${props => props.brand === 'TW' ? theme.colors.tw : (props.brand === 'Dale' ? theme.colors.dale : '#676767')};
    color: white;
  }
`;

export default Button;
