import React, { Component } from "react";
import { Globe, Phone } from "styled-icons/fa-solid";

import Table from "../shared/table/Table.js";
import HeaderRow from "../shared/table/HeaderRow.js";
import HeaderCell from "../shared/table/HeaderCell.js";
import Row from "../shared/table/Row.js";
import Cell from "../shared/table/Cell.js";
import Button from "../shared/Button";

class QuotesTable extends Component {
  render() {
    return (
      <Table>
        <tbody>
        <HeaderRow>
          <HeaderCell scope="col">Quote Ref</HeaderCell>
          <HeaderCell scope="col">Surname</HeaderCell>
          <HeaderCell scope="col">Company</HeaderCell>
          <HeaderCell scope="col">Net Value</HeaderCell>
          <HeaderCell scope="col">Last Modified</HeaderCell>
          <HeaderCell scope="col">Notes</HeaderCell>
          <HeaderCell />
        </HeaderRow>
        <Row>
          <Cell>1000/03</Cell>
          <Cell>Logan</Cell>
          <Cell>Timber Windows of Somewhere</Cell>
          <Cell>£2,500</Cell>
          <Cell>Thu 17 Jan 2019 12:22</Cell>
          <Cell>
            <span style={{color:"red"}}>Discount has been logged</span><br />
            <span style={{color:"green"}}>Customer has placed at least one order</span>
          </Cell>
          <Cell><Button>OPEN</Button></Cell>
        </Row>
        <Row>
          <Cell>950/01</Cell>
          <Cell>Donovan</Cell>
          <Cell>Timber Windows of Somewhere Else</Cell>
          <Cell>£6,500</Cell>
          <Cell>Wed 16 Jan 2019 09:43</Cell>
          <Cell>
          </Cell>
          <Cell><Button>OPEN</Button></Cell>
        </Row>
        <Row>
          <Cell>1000/02</Cell>
          <Cell>Guerra</Cell>
          <Cell>Timber Windows of Somewhere</Cell>
          <Cell>£4,000</Cell>
          <Cell>Wed 16 Jan 2019 08:55</Cell>
          <Cell>
            <span style={{color:"red"}}>Discount has been logged</span><br />
          </Cell>
          <Cell><Button>OPEN</Button></Cell>
        </Row>
        <Row>
          <Cell>1000/01</Cell>
          <Cell>Mora</Cell>
          <Cell>Timber Windows of Somewhere</Cell>
          <Cell>£1,750</Cell>
          <Cell>Thu 17 Jan 2019 12:22</Cell>
          <Cell>
          </Cell>
          <Cell><Button>OPEN</Button></Cell>
        </Row>


        </tbody>
      </Table>
    )
  }
};

export default QuotesTable;
