import React, { Component } from "react";

import AppContent from "../shared/layout/AppContent";
import ApertureHeading from "../shared/aperture/ApertureHeading";
import ContentContainer from "../shared/layout/ContentContainer";
import Column from "../shared/layout/Column";

import Card from "../shared/Card";
import CardHeading from "../shared/CardHeading";
import CardImage from "../shared/CardImage";
import CardContent from "../shared/CardContent";
import Button from "../shared/Button";

import doorimage from '../../assets/images/doors.png';
import kabacylinderimage from '../../assets/images/kabacylinders.png';
import choicesbrochureimage from '../../assets/images/choices.png';

class ApertureDemo extends Component {
  render() {
    return (
      <AppContent displayFont>
        <ApertureHeading centre large>
          Latest news from {this.props.brand === 'TW' ? 'Timber Windows' : 'Dale Joinery'}
        </ApertureHeading>
        {this.props.brand === 'TW'
          ?
          <ContentContainer>
            <Column width="33%" firstColumn>
              <Card>
                <CardHeading brand={this.props.brand}>
                  Brio Bi-Fold Doorset for sale
                </CardHeading>
                <CardImage src={doorimage}/>
                <CardContent>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

                </CardContent>
                <div style={{display: "flex", justifyContent: "center", width: "100%", margin: "10px"}}>
                  <Button>Read more</Button>
                </div>

              </Card>
            </Column>
            <Column width="33%">
              <Card>
                <CardHeading brand={this.props.brand}>
                  Kaba Door Cylinders
                </CardHeading>
                <CardImage src={kabacylinderimage}/>
                <CardContent>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

                </CardContent>
                <div style={{display: "flex", justifyContent: "center", width: "100%", margin: "10px"}}>
                  <Button>Read more</Button>
                </div>

              </Card>
            </Column>
            <Column width="33%">
              <Card>
                <CardHeading brand={this.props.brand}>
                  Latest Choices Brochure
                </CardHeading>
                <CardImage src={choicesbrochureimage}/>
                <CardContent>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

                </CardContent>
                <div style={{display: "flex", justifyContent: "center", width: "100%", margin: "10px"}}>
                  <Button>Read more</Button>
                </div>

              </Card>
            </Column>
          </ContentContainer>
          :
          <ContentContainer>
            <Column width="33%" firstColumn>
              <Card>
                <CardHeading brand={this.props.brand}>
                  Brio 5c Bi-Fold System Latest Update
                </CardHeading>
                <CardContent>
                  <p>
                    Brio UK have confirmed the new cills will arrive with their main distributor in Wk38.
                    The distributor is working with our factories to ensure they can complete the orders for any cills required for those products currently on hold as a matter of urgency.
                    Once shipping dates have been confirmed, we will then be able to advise of delivery dates from receipt of them in Tiptree.
                  </p>
                  <p>
                    Any new orders placed since the beginning of September will be on the current lead time, as shown in the system.
                  </p>
                  <p>
                    Once again, we would like to thank you for your patience with this matter.
                  </p>
                </CardContent>
                <div style={{display: "flex", justifyContent: "center", width: "100%", margin: "10px"}}>
                  <Button>Read more</Button>
                </div>

              </Card>
            </Column>
            <Column width="33%">
              <Card>
                <CardHeading brand={this.props.brand}>
                  Brio 5c Bi-Fold System
                </CardHeading>
                <CardContent>
                  <p>
                    Since the forced change over to the Brio 5c bottom hung bi-fold system back in April, It has come to our attention some issues regarding the Aluminium cill and bottom rollers.
                    We highlighted these as soon as these had been recognised to Brio UK.  Brio have now confirmed that there is an issue and are currently in the process of testing a solution.
                    This solution is changing the aluminium cill to a sturdier profile and changing bottom roller bearings for a more suitable grade.
                  </p>
                </CardContent>
                <div style={{display: "flex", justifyContent: "center", width: "100%", margin: "10px"}}>
                  <Button>Read more</Button>
                </div>

              </Card>
            </Column>
            <Column width="33%">
              <Card>
                <CardHeading brand={this.props.brand}>
                  System Maintenance
                </CardHeading>
                <CardContent>
                  <p>
                    There will be disruption to Aperture this weekend between 7PM on Saturday 9th June and 11PM on Sunday 10th June, due to essential maintenance.
                  </p>
                </CardContent>
                <div style={{display: "flex", justifyContent: "center", width: "100%", margin: "10px"}}>
                  <Button>Read more</Button>
                </div>

              </Card>
            </Column>
          </ContentContainer>
        }
        {this.props.brand === 'TW'
        &&
        <ContentContainer style={{marginTop: "25px", display: "flex", justifyContent: "space-between"}}>
          <Column firstColumn>
            <Card style={{height: "200px", width: "270px"}}>
              <CardHeading brand={this.props.brand}>
                SalesPro League
              </CardHeading>
              <CardContent>
                League
              </CardContent>
            </Card>
          </Column>
          <Column>
            <Card style={{height: "200px", width: "270px"}}>
              <CardHeading brand={this.props.brand}>
                Quote of the Week
              </CardHeading>
              <CardContent>
                Timber Windows of Fictional Town<br/>
                £70,500.12
              </CardContent>
            </Card>
          </Column>
          <Column>
            <Card style={{height: "200px", width: "270px"}}>
              <CardHeading brand={this.props.brand}>
                Order of the month
              </CardHeading>
              <CardContent>
                Timber Windows of Another Fictional Town<br/>
                £40,000.50
              </CardContent>
            </Card>
          </Column>
          <Column>
            <Card style={{height: "200px", width: "270px"}}>
              <CardHeading brand={this.props.brand}>
                Current Lead Times
              </CardHeading>
              <CardContent>
                6-8 weeks
              </CardContent>
            </Card>
          </Column>
        </ContentContainer>
        }
      </AppContent>
    )
  }
};

export default ApertureDemo;
