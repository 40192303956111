import React, { Component } from "react";
import Header from "../shared/header/Header";

class DashboardHeader extends Component {
  render() {
    let links = [];

    if (this.props.isAuthenticated) {
      links = [
        {
          to: "/",
          name: "Home"
        },{
          to: "/calendar",
          name: "Calendar"
        },{
          to: "/enquiries",
          name: "Enquiries"
        },{
          to: "/quotes",
          name: "Quotes"
        },{
          to: "/orders",
          name: "Orders"
        },{
          to: "/serviceRequests",
          name: "Service Requests"
        },{
          to: "/users",
          name: "Users"
        },{
          to: "/settings",
          name: "Settings"
        }
      ];
    }

    return (
      <Header
        brand="PWG"
        links={links}
        isAuthenticated={this.props.isAuthenticated}
        logout={this.props.logout}
      >
        <div style={{
          paddingTop: "5px",
          fontSize: "0.8em",
          fontWeight: "bold",
          letterSpacing: "2px"
        }}>
          17 JAN 2019 &sdot; WEEK 3
        </div>
      </Header>
    );
  }
}

export default DashboardHeader;
