import styled from "styled-components";
import {theme} from "../../../constants";
import {Link} from "react-router-dom";

const HeaderButtonLink = styled(Link)`
  font-size: 0.8em;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 15px 4px 5px 4px;
  margin: 0px 15px 0px 15px;
  cursor: pointer;
  border-bottom: 4px solid #ffffff;
  color: ${props => props.brand === 'TW' ? theme.colors.tw : (props.brand === 'PWG' ? theme.colors.dashboardTitle : (props.brand === 'Dale' && theme.colors.dale))};
  text-decoration: none;
  &:hover {
    border-bottom: 4px solid ${props => props.brand === 'TW' ? theme.colors.tw : (props.brand === 'PWG' && theme.colors.pwg)};
  }
`;

export default HeaderButtonLink;
