import React, { Component } from "react";
import { Check, ExclamationTriangle } from "styled-icons/fa-solid";

import AppContent from "../../shared/layout/AppContent";
import ContentContainer from "../../shared/layout/ContentContainer";
import Column from "../../shared/layout/Column";
import FrontpageWidgetHeading from "../../shared/dashboard/FrontpageWidgetHeading";
import ExternalLinkButton from "../../shared/ButtonLinkExternal";
import Welcome from "../../frontpage/Welcome";

import Image from "../../shared/Image";

import Table from "../../shared/table/Table";
import HeaderRow from "../../shared/table/HeaderRow";
import HeaderCell from "../../shared/table/HeaderCell";
import Row from "../../shared/table/Row";
import Cell from "../../shared/table/Cell";

import ApertureHeading from "../../shared/aperture/ApertureHeading";

import twBadge from "../../../assets/images/tw-badge.png";
import daleBadge from "../../../assets/images/dale-badge.png";
import deliveryMap from "../../../assets/images/deliveryMap.png";
import AppToolbar from "../AppToolbar";

class LogisticsProfile extends Component {
  render() {
    return (
      <AppContent>
        <ApertureHeading centre>
          Logistics Homepage
        </ApertureHeading>
        <hr />
        <ContentContainer brand="PWG" style={{marginTop:"20px", marginBottom: "20px"}}>
          <Column width="33%" firstColumn>
            <Welcome firstName="User"/>
          </Column>
          <Column width="66%" firstColumn>
            <FrontpageWidgetHeading brand="PWG" style={{marginTop:"32px"}}>
              Orders due for delivery this week
            </FrontpageWidgetHeading>
            <Image src={deliveryMap} />
            <Table>
              <tbody>
                <HeaderRow>
                  <HeaderCell style={{width:"20px"}} />
                  <HeaderCell>Order Ref</HeaderCell>
                  <HeaderCell>Status</HeaderCell>
                  <HeaderCell>Sales Status</HeaderCell>
                  <HeaderCell>Del Wk</HeaderCell>
                  <HeaderCell>Items</HeaderCell>
                  <HeaderCell>Checked</HeaderCell>
                  <HeaderCell>Order date</HeaderCell>
                  <HeaderCell />
                </HeaderRow>
                <Row success>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>075STO-1234-1-Surname-V1</Cell>
                  <Cell>In Warehouse</Cell>
                  <Cell>Balance Paid</Cell>
                  <Cell>3/2019</Cell>
                  <Cell>3</Cell>
                  <Cell><Check size={16} /> CHECKED</Cell>
                  <Cell>2019-01-11</Cell>
                  <Cell>
                    <ExternalLinkButton>Open</ExternalLinkButton>
                  </Cell>
                </Row>
                <Row danger>
                  <Cell><img src={daleBadge} /></Cell>
                  <Cell>115JEW-1234-1-Surname-V1</Cell>
                  <Cell>In Warehouse</Cell>
                  <Cell>On Account</Cell>
                  <Cell>3/2019</Cell>
                  <Cell>1</Cell>
                  <Cell><ExclamationTriangle size={16} /> NOT CHECKED</Cell>
                  <Cell>2019-01-15</Cell>
                  <Cell>
                    <ExternalLinkButton>Open</ExternalLinkButton>
                  </Cell>
                </Row>
                <Row success>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>075STO-1234-1-Surname-V1</Cell>
                  <Cell>In Warehouse</Cell>
                  <Cell>Balance Paid</Cell>
                  <Cell>3/2019</Cell>
                  <Cell>12</Cell>
                  <Cell><Check size={16} /> CHECKED</Cell>
                  <Cell>2019-01-11</Cell>
                  <Cell>
                    <ExternalLinkButton>Open</ExternalLinkButton>
                  </Cell>
                </Row>
                <Row success>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>075STO-1234-1-Surname-V1</Cell>
                  <Cell>In Warehouse</Cell>
                  <Cell>Balance Paid</Cell>
                  <Cell>3/2019</Cell>
                  <Cell>4</Cell>
                  <Cell><Check size={16} /> CHECKED</Cell>
                  <Cell>2019-01-11</Cell>
                  <Cell>
                    <ExternalLinkButton>Open</ExternalLinkButton>
                  </Cell>
                </Row>
              </tbody>
            </Table>
            <div style={{textAlign:"center", marginTop:"5px"}}>
              <ExternalLinkButton>Load more</ExternalLinkButton>
            </div>
          </Column>

        </ContentContainer>
        <ContentContainer brand="PWG" style={{marginTop:"20px", marginBottom: "20px"}}>
          <Column width="100%" firstColumn>
            <div style={{textAlign:"center", marginBottom:"20px", color:"red"}}>(Placeholder links to other apps below)</div>
            <AppToolbar profile={this.props.sales}/>
          </Column>
        </ContentContainer>
      </AppContent>
    )
  }
};

export default LogisticsProfile;



