import styled from "styled-components";
import { theme } from "../../../constants";

const ApertureHeading = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
  font-family: ${theme.fonts.heading};
  font-size: ${props => props.large ? '1.7em' : '1.3em'};
  text-transform: uppercase;
  text-align: ${props => props.centre ? 'center' : 'left'};
`;

export default ApertureHeading;
