import styled from "styled-components";

const HeaderButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
`;

export default HeaderButtonContainer;
