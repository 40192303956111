import styled from "styled-components";
import {theme} from "../../constants";

const Alert = styled.div`
  border-radius: 7px;
  padding: 20px;
  border: 1px solid ${theme.colors.pageHeading};
  background-color: #f9efe8;
  color: ${theme.colors.pageHeading};
  margin-bottom: 10px;
`;

export default Alert;
