import React, { Component } from "react";

import AppContent from "../../shared/layout/AppContent";
import ContentContainer from "../../shared/layout/ContentContainer";
import Column from "../../shared/layout/Column";
import FrontpageWidgetHeading from "../../shared/dashboard/FrontpageWidgetHeading";
import ExternalLinkButton from "../../shared/ButtonLinkExternal";
import Welcome from "../../frontpage/Welcome";

import Table from "../../shared/table/Table";
import HeaderRow from "../../shared/table/HeaderRow";
import HeaderCell from "../../shared/table/HeaderCell";
import Row from "../../shared/table/Row";
import Cell from "../../shared/table/Cell";
import InlineForm from "../../shared/form-controls/InlineForm";
import FormText from "../../shared/form-controls/FormText";
import FormInput from "../../shared/form-controls/FormInput";
import Select from "../../shared/form-controls/Select";
import Option from "../../shared/form-controls/Option";
import Panel from "../../shared/layout/Panel";
import Button from "../../shared/Button";

import twBadge from "../../../assets/images/tw-badge.png";
import daleBadge from "../../../assets/images/dale-badge.png";
import ApertureHeading from "../../shared/aperture/ApertureHeading";
import AppToolbar from "../AppToolbar";

class ServiceTeamProfile extends Component {
  render() {
    return (
      <AppContent>
        <ApertureHeading centre>
          Service Team Homepage
        </ApertureHeading>
        <hr />
        <ContentContainer brand="PWG" style={{marginTop:"20px", marginBottom: "20px"}}>
          <Column width="33%" firstColumn>
            <Welcome firstName="User"/>
          </Column>
          <Column width="66%" firstColumn>
            <FrontpageWidgetHeading brand="PWG">
              Service Requests
            </FrontpageWidgetHeading>
            <Panel>
              <InlineForm>
                <FormText>Filter:</FormText>
                <ContentContainer style={{alignItems:"center"}}>
                  <FormText>Age (days)</FormText>
                  <Select style={{width:"250px"}}>
                    <Option>Any</Option>
                    <Option>0 - 30</Option>
                    <Option>31 - 60</Option>
                  </Select>
                </ContentContainer>
                <Select style={{width:"250px"}}>
                  <Option>Select dealer</Option>
                </Select>
                <Button>Filter</Button>
              </InlineForm>
            </Panel>
            <Table style={{fontSize:"0.8em"}}>
              <tbody>
                <HeaderRow>
                  <HeaderCell style={{width:"20px"}} />
                  <HeaderCell>Date</HeaderCell>
                  <HeaderCell>Request No</HeaderCell>
                  <HeaderCell>Dealer/Order</HeaderCell>
                  <HeaderCell>Item Details</HeaderCell>
                  <HeaderCell>Days</HeaderCell>
                  <HeaderCell>Status</HeaderCell>
                  <HeaderCell />
                </HeaderRow>
                <Row>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>17 Jan 2019</Cell>
                  <Cell>TWS01-SR04</Cell>
                  <Cell>
                    Timber Windows of Somewhere<br />
                    075STO-1234-1-Surname-V1
                  </Cell>
                  <Cell>
                    1. Entrance Door
                  </Cell>
                  <Cell>1</Cell>
                  <Cell>New</Cell>
                  <Cell></Cell>
                </Row>
                <Row>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>16 Jan 2019</Cell>
                  <Cell>TWS01-SR03)</Cell>
                  <Cell>
                    Timber Windows of Somewhere<br />
                    075STO-1234-1-Surname-V1
                  </Cell>
                  <Cell>
                    2. Sash Window
                  </Cell>
                  <Cell>2</Cell>
                  <Cell>New</Cell>
                  <Cell></Cell>
                </Row>
                <Row>
                  <Cell><img src={daleBadge} /></Cell>
                  <Cell>16 Jan 2019</Cell>
                  <Cell>TWS01-SR03)</Cell>
                  <Cell>
                    Dale Dealer of Somewhere<br />
                    054DAL-1234-1-Surname-V1
                  </Cell>
                  <Cell>
                    1. Entrance door
                  </Cell>
                  <Cell>2</Cell>
                  <Cell>New</Cell>
                  <Cell></Cell>
                </Row>
                <Row>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>10 Jan 2019</Cell>
                  <Cell>TWS01-SR02)</Cell>
                  <Cell>
                    Timber Windows of Somewhere<br />
                    075STO-1234-1-Surname-V1
                  </Cell>
                  <Cell>
                    3. Circular Window
                  </Cell>
                  <Cell>3</Cell>
                  <Cell>PWG Site Visit</Cell>
                  <Cell></Cell>
                </Row>
              </tbody>
            </Table>
            <div style={{textAlign:"center", marginTop:"5px"}}>
              <ExternalLinkButton>Show all</ExternalLinkButton>
            </div>

            <FrontpageWidgetHeading brand="PWG" style={{marginTop:"30px"}}>
              Recent Notes
            </FrontpageWidgetHeading>
            <Table style={{fontSize:"0.8em"}}>
              <tbody>
                <HeaderRow>
                  <HeaderCell style={{width:"20px"}} />
                  <HeaderCell style={{width:"120px"}}>Date</HeaderCell>
                  <HeaderCell>Message</HeaderCell>
                  <HeaderCell />
                </HeaderRow>
                <Row>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>17 Jan 2019  11:13</Cell>
                  <Cell>
                    <div style={{marginBottom:"4px"}}><strong>Emily Taylor</strong> &sdot; Timber Windows of Somewhere</div>
                    Please can you send samples for product X and product Y to show the customer?
                  </Cell>
                  <Cell>
                    <Button>Open service request</Button>
                  </Cell>
                </Row>
                <Row>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>17 Jan 2019  09:43</Cell>
                  <Cell>
                    <div style={{marginBottom:"4px"}}><strong>Joe Miller</strong> &sdot; Timber Windows of Somewhere Else</div>
                    I have spoken to customer and they can do afternoon of X January for site visit? This...
                  </Cell>
                  <Cell>
                    <Button>Open service request</Button>
                  </Cell>
                </Row>
                <Row>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>17 Jan 2019  09:43</Cell>
                  <Cell>
                    <div style={{marginBottom:"4px"}}><strong>David Smith</strong> &sdot; Timber Windows of Somewhere Else</div>
                    Frame has cracked due to water. Please see attached photos and let us know when...
                  </Cell>
                  <Cell>
                    <Button>Open service request</Button>
                  </Cell>
                </Row>
              </tbody>
            </Table>

            <div style={{textAlign:"center", marginTop:"5px"}}>
              <ExternalLinkButton>Show all</ExternalLinkButton>
            </div>

          </Column>

        </ContentContainer>
        <ContentContainer brand="PWG" style={{marginTop:"20px", marginBottom: "20px"}}>
          <Column width="100%" firstColumn>
            <div style={{textAlign:"center", marginBottom:"20px", color:"red"}}>(Placeholder links to other apps below)</div>
            <AppToolbar profile={this.props.sales}/>
          </Column>
        </ContentContainer>
      </AppContent>
    )
  }
};

export default ServiceTeamProfile;



