// @Cleanup - we will need a shared component which can link to both internal and external sites
import styled from "styled-components";
import {theme} from "../../constants";

const ExternalLinkButton = styled.a`
  padding: 4px 25px 4px 25px;
  display: inline-block;
  border: 1px solid ${theme.colors.border};
  color: ${theme.colors.darkGrey};
  background-color: white;
  font-family: ${theme.fonts.dashboard};
  font-size: 0.9em;
  height: 1.2em;
  border-radius: 4px;
  text-align: center;
  width: ${props => props.width ? props.width : 'auto'};
  margin-top: ${props => props.block ? '10px' : '0px'};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.brand === 'TW' ? theme.colors.tw : theme.colors.lightGreen};
  }
`;

export default ExternalLinkButton;
