import styled from "styled-components";
import { theme } from "../../../constants";

const DashboardHeading = styled.div`
  margin-top: ${props => props.large ? '20px' : '10px'};;
  margin-bottom: 20px;
  font-size: ${props => props.large ? '1.8em' : '1.2em'};
  letter-spacing: ${props => props.large ? '5px' : '1px'};
  color: ${theme.colors.pageHeading};
  font-family: ${theme.fonts.dashboard};
  font-weight: ${props => props.large ? 'normal' : 'bold'};
  text-transform: none;
  text-align: ${props => props.centre ? 'center' : 'left'};
`;

export default DashboardHeading;
