import React, { Component } from "react";

import QuotesTable from "./QuotesTable";

import ApertureHeading from "../shared/aperture/ApertureHeading";

import InlineForm from "../shared/form-controls/InlineForm";
import FormControl from "../shared/form-controls/FormControl";
import FormInput from "../shared/form-controls/FormInput";
import FormText from "../shared/form-controls/FormText";
import DashboardContent from "../shared/dashboard/DashboardContent";
import Button from "../shared/Button";
import Panel from "../shared/layout/Panel";


class Quotes extends Component {
  render() {
    return (
      <DashboardContent brand="PWG">
        <ApertureHeading centre>
          Quotes
        </ApertureHeading>
        <Panel>
          <InlineForm>
            <FormControl>
              <FormText width='70px'>Name</FormText>
              <FormInput />
            </FormControl>
            <FormControl>
              <FormText width='70px'>Type</FormText>
              <FormInput />
            </FormControl>
            <FormControl>
              <FormText width='70px'>Town</FormText>
              <FormInput />
            </FormControl>
            <Button width='80px'>Reset</Button>
          </InlineForm>
          <InlineForm>
            <FormControl>
              <FormText width='70px'>Day</FormText>
              <FormInput />
            </FormControl>
            <FormControl>
              <FormText width='70px'>Status</FormText>
              <FormInput />
            </FormControl>
            <FormControl>
              <FormText width='70px'>Installer</FormText>
              <FormInput />
            </FormControl>
            <Button width='80px'>Filter</Button>
          </InlineForm>
        </Panel>
        <QuotesTable />
        <div style={{display:"flex", justifyContent: "center", width:"100%", marginTop:"10px"}}>
          <Button>Load more</Button>
        </div>
      </DashboardContent>
    )
  }
};

export default Quotes;
