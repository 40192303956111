import React, { Component } from "react";
import { Exclamation } from "styled-icons/fa-solid";

import AppContent from "../shared/layout/AppContent";
import ApertureHeading from "../shared/aperture/ApertureHeading";
import Button from "../shared/Button";
import Alert from "../shared/Alert";
import InlineForm from "../shared/form-controls/InlineForm";
import FormControl from "../shared/form-controls/FormControl";
import FormInput from "../shared/form-controls/FormInput";
import FormText from "../shared/form-controls/FormText";
import Select from "../shared/form-controls/Select";
import Option from "../shared/form-controls/Option";
import Panel from "../shared/layout/Panel";

import QuotesTable from "./QuotesTable";

class ApertureQuotes extends Component {
  render() {
    return (
      <AppContent>
        <ApertureHeading centre>
          Quotes
        </ApertureHeading>
        <Alert>
          <InlineForm>
            <div>
              <Exclamation size={16}/> There are 4 retail quotes expiring within 5 days
            </div>
            <Button style={{border:"1px solid #c5651f"}}>
              Show expiring quotes
            </Button>
          </InlineForm>
        </Alert>
        <Panel>
          <InlineForm>
            <FormControl>
              <FormText width='70px'>Search</FormText>
              <Select>
                <Option>Surname</Option>
                <Option>Reference</Option>
                <Option>Address</Option>
                <Option>Salesperson</Option>
                <Option>Date</Option>
              </Select>
              <FormText width='70px'>contains</FormText>
              <FormInput />
              <Button width='80px' style={{marginLeft:"20px"}}>Search</Button>
              <Button width='120px' style={{marginLeft:"20px"}}>Reset Search</Button>
            </FormControl>
            <FormControl>
              <Button width='100px'>New Customer</Button>
            </FormControl>
          </InlineForm>
        </Panel>
        <QuotesTable />
        <div style={{display:"flex", justifyContent: "center", width:"100%", marginTop:"10px"}}>
          <Button>Load more</Button>
        </div>
      </AppContent>
    )
  }
};

export default ApertureQuotes;
