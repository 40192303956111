import React, { Component } from "react";

import AppContent from "../../shared/layout/AppContent";
import ContentContainer from "../../shared/layout/ContentContainer";
import Column from "../../shared/layout/Column";
import FrontpageWidgetHeading from "../../shared/dashboard/FrontpageWidgetHeading";
import ExternalLinkButton from "../../shared/ButtonLinkExternal";
import Welcome from "../../frontpage/Welcome";
import AppToolbar from "../../frontpage/AppToolbar";

import Table from "../../shared/table/Table";
import HeaderRow from "../../shared/table/HeaderRow";
import HeaderCell from "../../shared/table/HeaderCell";
import Row from "../../shared/table/Row";
import Cell from "../../shared/table/Cell";
import ApertureHeading from "../../shared/aperture/ApertureHeading";

import Panel from "../../shared/layout/Panel";
import InlineForm from "../../shared/form-controls/InlineForm";
import FormInput from "../../shared/form-controls/FormInput";
import Button from "../../shared/Button";

import InfoContainer from "../../shared/InfoContainer";
import Label from "../../shared/Label";
import Bar from "../../shared/Bar";

import twBadge from "../../../assets/images/tw-badge.png";
import daleBadge from "../../../assets/images/dale-badge.png";

class OrderProcessorProfile extends Component {
  render() {
    return (
      <AppContent>
        <ApertureHeading centre>
          Order Processing Homepage
        </ApertureHeading>
        <hr />
        <ContentContainer brand="PWG" style={{marginTop:"20px", marginBottom: "20px"}}>
          <Column width="33%" firstColumn>
            <Welcome firstName="User"/>
            <FrontpageWidgetHeading style={{marginTop:"25px"}}>
              Find order
            </FrontpageWidgetHeading>
            <Panel style={{padding:"10px 25px 10px 25px"}}>
              <p>
                Enter surname or order reference:
              </p>
              <InlineForm>

                <FormInput />
                <Button>Search</Button>
              </InlineForm>
            </Panel>
          </Column>
          <Column width="66%">
            <FrontpageWidgetHeading brand="PWG">
              Order Processing Summary
            </FrontpageWidgetHeading>
            <ContentContainer>
              <div>
                <InfoContainer>
                  <Label>Preparing Contract</Label>
                  <ContentContainer style={{flex:"flex-start", alignItems:"center"}}><Bar number={15} /> 15</ContentContainer>
                </InfoContainer>
                <InfoContainer>
                  <Label>Contracts Ready</Label>
                  <ContentContainer style={{flex:"flex-start", alignItems:"center"}}><Bar number={35} /> 35</ContentContainer>
                </InfoContainer>
                <InfoContainer>
                  <Label>Contracts returned</Label>
                  <ContentContainer style={{flex:"flex-start", alignItems:"center"}}><Bar number={3} /> 3</ContentContainer>
                </InfoContainer>
                <InfoContainer>
                  <Label>Contracts sent</Label>
                  <ContentContainer style={{flex:"flex-start", alignItems:"center"}}><Bar number={32} /> 32</ContentContainer>
                </InfoContainer>
              </div>
              <div>

                <Button style={{width:"200px"}}>Open Order Processing</Button><br />
                <Button style={{width:"200px", marginTop:"10px"}}>Special Items</Button>
                {/*<ExternalLinkButton style={{width:"200px"}} href="https://my.timberwindows.com/cgi-bin/tw_internal_list_sales.cgi">Open Order Processing</ExternalLinkButton><br />*/}
                {/*<ExternalLinkButton style={{width:"200px", marginTop:"10px"}} href="https://my.timberwindows.com/cgi-bin/tw_internal_list_sales.cgi">Special Items</ExternalLinkButton>*/}
              </div>
            </ContentContainer>
            <FrontpageWidgetHeading brand="PWG" style={{marginTop:"32px"}}>
              Orders assigned to me
            </FrontpageWidgetHeading>
            <Table>
              <tbody>
                <HeaderRow>
                  <HeaderCell style={{width:"20px"}} />
                  <HeaderCell>Order Ref</HeaderCell>
                  <HeaderCell>Status</HeaderCell>
                  <HeaderCell>Del Wk</HeaderCell>
                  <HeaderCell>Original</HeaderCell>
                  <HeaderCell>Edited</HeaderCell>
                  <HeaderCell>Order date</HeaderCell>
                  <HeaderCell />
                </HeaderRow>
                <Row>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>075STO-1234-1-Surname-V1</Cell>
                  <Cell>Preparing Contract (New)</Cell>
                  <Cell>10/2019</Cell>
                  <Cell>£6,000</Cell>
                  <Cell>£5,000</Cell>
                  <Cell>2019-01-11</Cell>
                  <Cell>
                    <ExternalLinkButton>OPEN</ExternalLinkButton>
                  </Cell>
                </Row>
                <Row>
                  <Cell><img src={daleBadge} /></Cell>
                  <Cell>115JEW-1234-1-Surname-V1</Cell>
                  <Cell>Preparing Contract (On A/C)</Cell>
                  <Cell>10/2019</Cell>
                  <Cell>£6,000</Cell>
                  <Cell>£5,000</Cell>
                  <Cell>2019-01-15</Cell>
                  <Cell>
                    <ExternalLinkButton>OPEN</ExternalLinkButton>
                  </Cell>
                </Row>
                <Row>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>075STO-1234-1-Surname-V1</Cell>
                  <Cell>Contract Ready (New)</Cell>
                  <Cell>10/2019</Cell>
                  <Cell>£6,000</Cell>
                  <Cell>£5,000</Cell>
                  <Cell>2019-01-11</Cell>
                  <Cell>
                    <ExternalLinkButton>OPEN</ExternalLinkButton>
                  </Cell>
                </Row>
                <Row>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>075STO-1234-1-Surname-V1</Cell>
                  <Cell>Contract Ready (Awaiting Dep)</Cell>
                  <Cell>10/2019</Cell>
                  <Cell>£6,000</Cell>
                  <Cell>£5,000</Cell>
                  <Cell>2019-01-11</Cell>
                  <Cell>
                    <ExternalLinkButton>OPEN</ExternalLinkButton>
                  </Cell>
                </Row>
                <Row>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>075STO-1234-1-Surname-V1</Cell>
                  <Cell>Contract Ready (Awaiting Dep)</Cell>
                  <Cell>10/2019</Cell>
                  <Cell>£6,000</Cell>
                  <Cell>£5,000</Cell>
                  <Cell>2019-01-11</Cell>
                  <Cell>
                    <ExternalLinkButton>OPEN</ExternalLinkButton>
                  </Cell>
                </Row>
              </tbody>
            </Table>
            <div style={{textAlign:"center", marginTop:"5px"}}>
              <ExternalLinkButton>Load more</ExternalLinkButton>
            </div>
          </Column>

        </ContentContainer>
        <ContentContainer brand="PWG" style={{marginTop:"20px", marginBottom: "20px"}}>
          <Column width="100%" firstColumn>
            <div style={{textAlign:"center", marginBottom:"20px", color:"red"}}>(Placeholder links to other apps below)</div>
            <AppToolbar profile={this.props.sales}/>
          </Column>
        </ContentContainer>
      </AppContent>
    )
  }
};

export default OrderProcessorProfile;



