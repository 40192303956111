import './prototype.css';
export const headerDateFormat = "dddd Do MMMM YYYY";
export const datepickerFormat = "DD/MM/YYYY";

export const theme = {
  fonts: {
    body: "Futura, Verdana, Arial, Tahoma, sans-serif",
    dashboard: "Helvetica, sans-serif",
    heading: "Kaleko, Arial, sans-serif",
    aperture: "Helvetica"
  },
  colors: {
    veryLightGrey: '#efefef',
    error: "#ff0000",
    primary: "#7a8e8d",
    heading: "#5D6797",
    pageHeading: "#c5651f",
    gray2: "#aeaeae",
    mediumGrey: "#898989",
    darkGrey: "#343434",
    border: "#aeaeae",
    darkBorder: '#636a0c',
    toolTipBackground: "#dde6e7",
    pwg: "#858b1f",
    tw: "#91a5a4",
    dale: "#3e4c57",
    lightGreen: "#eff2de",
    dashboardTitle: "#545b21",
    calendarEventPalette: [
      "#ffffff", "#fb7c3f", "#e0e4c2", "#bdd8c3", "e7a652"
    ],
    loadingMessagesBackground: "#858b1f",
    form: {
      invalidInputHighlight: "#ff0000"
    },
    table: {
      tableHeader: "#dbe5c5",
      tableCellBorder: "#ccc",
      rowBackgroundColourOdd: "#f2f2f2",
      rowBackgroundColourEven: "#fff",
      rowBackgroundColourHighlight: "#dbe5c5"
    },
    sideMenu: {
      background: "#fffecb"
    },
    header: {
      tabBackgroundLight: "#f2f6e4",
      tabBackground: "#d0d3b1",
      tabBorder: "#d0d3b1"
    },
    leaveCalendar: {
      userLeaveRequest: "#fb7c3f",
      otherPersonLeaveRequest: "#858b1f",
      rejectedLeaveRequest: "#676767",
      publicHoliday: "#669933",
      mandatoryHolidayDay: "#669933"
    }
  },
  zIndexes: {
    menu: 999
  }
};
