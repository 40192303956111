import React, { Component } from "react";

import OrderProcessorProfile from "./frontpage/profiles/OrderProcessorProfile";
import SalesProfile from "./frontpage/profiles/SalesProfile";
import LogisticsProfile from "./frontpage/profiles/LogisticsProfile";
import ServiceTeamProfile from "./frontpage/profiles/ServiceTeamProfile";
import StandardProfile from "./frontpage/profiles/StandardProfile";

class Frontpage extends Component {
  render() {
    // Render specific frontpages depending on user profile
    if (this.props.profile === 'orderProcessing')
      return <OrderProcessorProfile />;
    else if (this.props.profile === 'sales')
      return <SalesProfile />;
    else if (this.props.profile === 'logistics')
      return <LogisticsProfile />;
    else if (this.props.profile === 'serviceTeam')
      return <ServiceTeamProfile />;
    else
      return <StandardProfile />;
  }
};

export default Frontpage;
