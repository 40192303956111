import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";

import AppContainer from "./components/shared/layout/AppContainer";

import LoginForm from "./components/LoginForm";
import AppSummary from "./components/AppSummary";

import DashboardHeader from "./components/header/DashboardHeader";
import Frontpage from "./components/Frontpage";
import Calendar from "./components/dashboard/Calendar";
import Enquiries from "./components/dashboard/Enquiries";
import Quotes from "./components/dashboard/Quotes";

import ApertureHeader from "./components/apertureDemo/ApertureHeader";
import ApertureDemo from "./components/apertureDemo/ApertureDemo";
import ApertureLeads from "./components/apertureDemo/Leads";
import ApertureCRM from "./components/apertureDemo/CrmDashboard";
import ApertureQuotes from "./components/apertureDemo/Quotes";
import ApertureOrders from "./components/apertureDemo/Orders";
import ApertureTeam from "./components/apertureDemo/Team";

import ApertureHeading from "./components/shared/aperture/ApertureHeading";

class App extends Component {
  state = {
    isLoggedIn: false,
    profile: '',
    brand: 'PWG',
    isShowLogoTooltip: true
  };

  componentDidMount = () => {
    if (this.props.match.path === '/')
      this.setState({profile: "", isLoggedIn: false});
    if (this.props.match.path === '/aperture')
      this.setState({isLoggedIn: true, profile: 'twDealer', brand: 'TW'});
  };

  hideLogoTooltip = () => {
    this.setState({isShowLogoTooltip: false});
  };

  logout = () => {
    this.setState({
      isLoggedIn: false,
      profile: '',
      brand: 'PWG'
    })
  };

  automaticLogin = async profile => {
    this.setState({
      isLoggedIn: true,
      profile: profile
    });

    if (profile === 'twDealer') {
      await this.setState({brand: "TW"});
      this.props.history.push('/aperture');
    }

    if (profile === 'daleDealer') {
      await this.setState({brand: "Dale"});
      this.props.history.push('/aperture');
    }
  };

  authenticate = (credentials) => {
    this.automaticLogin('orderProcessing');
  };

  /*
   * Profiles available in this demo:
   * - orderProcessing
   * - sales
   * - logistics
   * - twDealer
   * - daleDealer
   */
  render() {
    return (
      <AppContainer>
        {(!this.state.isLoggedIn && this.props.location.pathname !== '/') && <Redirect to='/' />}

        {!this.state.isLoggedIn && <DashboardHeader isAuthenticated={false} logout={this.logout} />}

        {this.state.isLoggedIn && (
          this.state.profile === 'twDealer' ? <ApertureHeader isAuthenticated={true} brand="TW" logout={this.logout} /> : (
            this.state.profile === 'daleDealer' ? <ApertureHeader isAuthenticated={true} brand="Dale" logout={this.logout}/> : (
              <DashboardHeader isAuthenticated={true} logout={this.logout} />
            )
          )
        )}

        {this.state.isLoggedIn ?
          <Route path="/" render={() => <Frontpage profile={this.state.profile} />} exact />
          :
          <Route path="/" render={() =>
            <LoginForm
              authenticate={this.authenticate}
              isShowLogoTooltip={this.state.isShowLogoTooltip}
              hideLogoTooltip={this.hideLogoTooltip}
              automaticLogin={this.automaticLogin}
            />
          } exact />
        }
        <Route path="/pwgApps" component={AppSummary} exact />

        <Route path="/calendar" render={() => { return <Calendar />}} />
        <Route path="/enquiries" render={() => { return <Enquiries />}} />
        <Route path="/quotes" render={() => { return <Quotes />}} />
        <Route path="/orders" render={() => { return <ApertureHeading centre>Placeholder: Orders will be here</ApertureHeading>}} />
        <Route path="/serviceRequests" render={() => { return <ApertureHeading centre>Placeholder: Service requests will be here</ApertureHeading>}} />
        <Route path="/users" render={() => { return <ApertureHeading centre>Placeholder: PWG User management will be here</ApertureHeading>}} />
        <Route path="/settings" render={() => { return <ApertureHeading centre>Placeholder: PWG settings will be here</ApertureHeading>}} />

        <Route path="/aperture" render={() => { return <ApertureDemo brand={this.state.brand} />}} exact />
        <Route path="/aperture/leads" component={ApertureLeads} exact />
        <Route path="/aperture/crm" component={ApertureCRM} exact />
        <Route path="/aperture/quotes" component={ApertureQuotes} exact />
        <Route path="/aperture/orders" component={ApertureOrders} exact />
        <Route path="/aperture/partsShop" render={() => { return <ApertureHeading centre>Placeholder: Parts Shop will be here</ApertureHeading>}} />
        <Route path="/aperture/support" render={() => { return <ApertureHeading centre>Placeholder: Support will be here</ApertureHeading>}} />
        <Route path="/aperture/salesforce" component={ApertureTeam} exact />
        <Route path="/aperture/settings" render={() => { return <ApertureHeading centre>Placeholder: Settings will be here</ApertureHeading>}} />

        {/*<Route path="/mumforddashboard" component={MumfordDashboard} />*/}


      </AppContainer>
    );
  }
}

export default withRouter(App);
