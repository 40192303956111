import styled from "styled-components";
import { theme } from "../../../constants";
import DashboardHeading from "./DashboardHeading";

const FrontpageWidgetHeading = styled(DashboardHeading)`
  font-size: 1.1em;
  color: ${theme.colors.pwg};
  text-align: ${props => props.centre ? 'center' : 'justify'}
  width: '100%';
  border-bottom: 1px solid ${theme.colors.pwg};
  padding-botton: 4px;
`;

export default FrontpageWidgetHeading;
