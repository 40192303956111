import React, { Component } from "react";

import AppContent from "../shared/layout/AppContent";
import ApertureHeading from "../shared/aperture/ApertureHeading";
import InlineForm from "../shared/form-controls/InlineForm";
import FormControl from "../shared/form-controls/FormControl";
import FormInput from "../shared/form-controls/FormInput";
import FormText from "../shared/form-controls/FormText";

import Button from "../shared/Button";
import Panel from "../shared/layout/Panel";
import Select from "../shared/form-controls/Select";
import Option from "../shared/form-controls/Option";
import Image from "../shared/Image";

import leadsmapimage from '../../assets/images/leads-map.png';
import LeadsTable from "./LeadsTable";

class ApertureLeads extends Component {
  render() {
    return (
      <AppContent>
        <ApertureHeading centre>
          Leads
        </ApertureHeading>
        <Panel>
          <InlineForm>
            <FormControl>
              <FormText width='70px'>Search</FormText>
              <Select>
                <Option>Name</Option>
                <Option>Date</Option>
                <Option>Postcode</Option>
              </Select>
              <FormText width='70px'>contains</FormText>
              <FormInput />
              <Button width='80px' style={{marginLeft:"20px"}}>Search</Button>
              <Button width='120px' style={{marginLeft:"20px"}}>Reset Search</Button>
            </FormControl>
          </InlineForm>
        </Panel>
        <Image src={leadsmapimage} />
        <LeadsTable />
        <div style={{display:"flex", justifyContent: "center", width:"100%", marginTop:"10px"}}>
          <Button>Load more</Button>
        </div>
      </AppContent>
    )
  }
};

export default ApertureLeads;
