import React, { Component } from "react";
import { FilePdf, PoundSign, CaretRight } from "styled-icons/fa-solid";

import Table from "../shared/table/Table";
import Row from "../shared/table/Row";
import Cell from "../shared/table/Cell";
import HeaderCell from "../shared/table/HeaderCell";
import HeaderRow from "../shared/table/HeaderRow";

class OrdersTable extends Component {
  render() {
    return (
      <Table>
        <thead>
        <HeaderRow>
          <HeaderCell />
          <HeaderCell scope="col">Order Ref</HeaderCell>
          <HeaderCell scope="col">Your Ref</HeaderCell>
          <HeaderCell scope="col">Date Ordered</HeaderCell>
          <HeaderCell scope="col">Date Contract Available</HeaderCell>
          <HeaderCell scope="col">Date Signed Off</HeaderCell>
          <HeaderCell scope="col">Financial status</HeaderCell>
          <HeaderCell scope="col">Logistics status</HeaderCell>
          <HeaderCell scope="col"><PoundSign size={14} /></HeaderCell>
          <HeaderCell scope="col">PDF</HeaderCell>
          <HeaderCell scope="col">Sign Off</HeaderCell>
          <HeaderCell scope="col">Order Processor</HeaderCell>
        </HeaderRow>
        </thead>
        <tbody>
        <Row style={{backgroundColor:"#dfead2"}}>
          <Cell><CaretRight size={16} /></Cell>
          <Cell>075STO-1234-1-Pierce-V1</Cell>
          <Cell>Pierce 1</Cell>
          <Cell>09/12/2018</Cell>
          <Cell>10/12/2018</Cell>
          <Cell>11/12/2018</Cell>
          <Cell><FilePdf size={16} style={{color: "#7a8e8d", marginRight:"10px"}}/> Awaiting balance</Cell>
          <Cell>Due week 5/2019</Cell>
          <Cell>Y ---</Cell>
          <Cell><FilePdf size={16} style={{color: "#7a8e8d", marginRight:"10px"}}/> View Order</Cell>
          <Cell></Cell>
          <Cell>Someone Else</Cell>
        </Row>
        <Row>
          <Cell><CaretRight size={16} /></Cell>
          <Cell>075STO-1002-1-Boardman-V1</Cell>
          <Cell>Boardman 4</Cell>
          <Cell>24/11/2018</Cell>
          <Cell>29/11/2018</Cell>
          <Cell>01/12/2018</Cell>
          <Cell>Deposit Paid</Cell>
          <Cell>Due week 3/2019</Cell>
          <Cell>Y ---</Cell>
          <Cell><FilePdf size={16} style={{color: "#7a8e8d", marginRight:"10px"}}/> View Order</Cell>
          <Cell></Cell>
          <Cell>Someone Else</Cell>
        </Row>
        </tbody>
      </Table>
    )
  }
};

export default OrdersTable;
