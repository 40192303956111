import React, { Component } from "react";
import { Globe, Phone } from "styled-icons/fa-solid";

import Table from "../shared/table/Table.js";
import HeaderRow from "../shared/table/HeaderRow.js";
import HeaderCell from "../shared/table/HeaderCell.js";
import Row from "../shared/table/Row.js";
import Cell from "../shared/table/Cell.js";

class EnquiriesTable extends Component {
  render() {
    return (
      <Table>
        <tbody>
          <HeaderRow>
            <HeaderCell />
            <HeaderCell scope="col">Name</HeaderCell>
            <HeaderCell scope="col">Type</HeaderCell>
            <HeaderCell scope="col">Postcode</HeaderCell>
            <HeaderCell scope="col">Route</HeaderCell>
            <HeaderCell scope="col">Date</HeaderCell>
            <HeaderCell scope="col">Actioned</HeaderCell>
            <HeaderCell scope="col">Status</HeaderCell>
          </HeaderRow>
          <Row>
            <Cell><input type="checkbox" /></Cell>
            <Cell>Theodore O'Quinn</Cell>
            <Cell>-</Cell>
            <Cell>TN1 5RT</Cell>
            <Cell><Phone size={16} style={{color:"#565656"}}/></Cell>
            <Cell>Tue 18 Dec 18 19:22</Cell>
            <Cell>-</Cell>
            <Cell>-</Cell>
          </Row>
          <Row>
            <Cell><input type="checkbox" /></Cell>
            <Cell>Adrian Atkins</Cell>
            <Cell>H</Cell>
            <Cell>BR3 9LW</Cell>
            <Cell><Globe size={16} style={{color:"#565656"}}/></Cell>
            <Cell>Tue 18 Dec 18 10:47</Cell>
            <Cell>Elwyn Windows Ltd</Cell>
            <Cell>New</Cell>
          </Row>
          <Row>
            <Cell><input type="checkbox" /></Cell>
            <Cell>Sara Donovan</Cell>
            <Cell>H</Cell>
            <Cell>RG7 4PE</Cell>
            <Cell><Globe size={16} style={{color:"#565656"}}/></Cell>
            <Cell>Tue 18 Dec 18 09:11</Cell>
            <Cell>Stonehouse Corinium</Cell>
            <Cell>Progressed</Cell>
          </Row>
          <Row>
            <Cell><input type="checkbox" /></Cell>
            <Cell>Sally Logan</Cell>
            <Cell>H</Cell>
            <Cell>PR3 2RP</Cell>
            <Cell><Globe size={16} style={{color:"#565656"}}/></Cell>
            <Cell>Tue 18 Dec 18 08:39</Cell>
            <Cell>Timber Windows of ..</Cell>
            <Cell>New</Cell>
          </Row>
          <Row>
            <Cell><input type="checkbox" /></Cell>
            <Cell>Dale Guerra</Cell>
            <Cell>H</Cell>
            <Cell>PR3 3AJ</Cell>
            <Cell><Globe size={16} style={{color:"#565656"}}/></Cell>
            <Cell>Tue 18 Dec 18 08:39</Cell>
            <Cell>Timber Windows of ..</Cell>
            <Cell>New</Cell>
          </Row>
          <Row>
            <Cell><input type="checkbox" /></Cell>
            <Cell>Tanya Berg</Cell>
            <Cell>-</Cell>
            <Cell>CB1 8BS</Cell>
            <Cell><Globe size={16} style={{color:"#565656"}}/></Cell>
            <Cell>Mon 17 Dec 18 22:20</Cell>
            <Cell>Timber Windows of ..</Cell>
            <Cell>New</Cell>
          </Row>
          <Row>
            <Cell><input type="checkbox" /></Cell>
            <Cell>Gregory Mora</Cell>
            <Cell>-</Cell>
            <Cell>TN1 3RP</Cell>
            <Cell><Phone size={16} style={{color:"#565656"}}/></Cell>
            <Cell>Tue 18 Dec 18 19:22</Cell>
            <Cell>-</Cell>
            <Cell>-</Cell>
          </Row>
          <Row>
            <Cell><input type="checkbox" /></Cell>
            <Cell>Fleur Barrera</Cell>
            <Cell>H</Cell>
            <Cell>BR3 4GE</Cell>
            <Cell><Globe size={16} style={{color:"#565656"}}/></Cell>
            <Cell>Tue 18 Dec 18 10:47</Cell>
            <Cell>Elwyn Windows Ltd</Cell>
            <Cell>New</Cell>
          </Row>
        </tbody>
      </Table>
    )
  }
};

export default EnquiriesTable;
