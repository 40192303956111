import React, { Component } from "react";
import { CalendarAlt } from "styled-icons/fa-solid";
import { User } from "styled-icons/fa-solid";

import FrontpageWidgetHeading from "../shared/dashboard/FrontpageWidgetHeading";
import FrontpageWidgetSubHeading from "../shared/dashboard/FrontpageWidgetSubHeading";
import InfoContainer from "../shared/InfoContainer";
import ButtonLink from "../shared/ButtonLink";
import Label from "../shared/Label";


class Welcome extends Component {
  render() {
    return (
      <div>
        <FrontpageWidgetHeading>
          Welcome, {this.props.firstName}
        </FrontpageWidgetHeading>
        <FrontpageWidgetSubHeading>Upcoming Events</FrontpageWidgetSubHeading>
        <InfoContainer>
          <Label width="100px">10:00</Label>
          <div>Phone call with Firstname Lastname</div>
        </InfoContainer>
        <InfoContainer>
          <Label width="100px">14:00</Label>
          <div>Team meeting</div>
        </InfoContainer>
        <div style={{marginTop:"10px", display:"flex", justifyContent:"center"}}>
        <ButtonLink to='/calendar'>
          <CalendarAlt size={16} /> Go to calendar
        </ButtonLink>
        </div>
        <div style={{display:"flex", justifyContent:"flex-end", marginTop:"10px"}}>
        </div>
        <FrontpageWidgetSubHeading style={{}}>Team Information</FrontpageWidgetSubHeading>
        <InfoContainer>
          <Label width="100px"><User size={16} /></Label>
          <div>Firstname Lastname on leave until 18 Jan</div>
        </InfoContainer>
        <InfoContainer>
          <Label width="100px"><User size={16} /></Label>
          <div>You are on leave 28 Jan - 1 Feb</div>
        </InfoContainer>
      </div>
    )
  }
};

export default Welcome;



