import React, { Component } from "react";
import { MobileAlt, Envelope, Globe } from "styled-icons/fa-solid";

import AppContent from "../../shared/layout/AppContent";
import ContentContainer from "../../shared/layout/ContentContainer";
import Column from "../../shared/layout/Column";
import FrontpageWidgetHeading from "../../shared/dashboard/FrontpageWidgetHeading";
import ExternalLinkButton from "../../shared/ButtonLinkExternal";
import Welcome from "../../frontpage/Welcome";

import Table from "../../shared/table/Table";
import HeaderRow from "../../shared/table/HeaderRow";
import HeaderCell from "../../shared/table/HeaderCell";
import Row from "../../shared/table/Row";
import Cell from "../../shared/table/Cell";

import InfoContainer from "../../shared/InfoContainer";
import Label from "../../shared/Label";
import Bar from "../../shared/Bar";
import Button from "../../shared/Button";
import Panel from "../../shared/layout/Panel";

import FormControl from "../../shared/form-controls/FormControl";
import FormInput from "../../shared/form-controls/FormInput";
import Select from "../../shared/form-controls/Select";
import Option from "../../shared/form-controls/Option";

import twBadge from "../../../assets/images/tw-badge.png";
import AppToolbar from "../AppToolbar";
import ApertureHeading from "../../shared/aperture/ApertureHeading";

class SalesProfile extends Component {
  render() {
    return (
      <AppContent>
        <ApertureHeading centre>
          Sales Homepage
        </ApertureHeading>
        <hr />
        <ContentContainer brand="PWG" style={{marginTop:"20px", marginBottom: "20px"}}>
          <Column width="33%" firstColumn>
            <Welcome firstName="User"/>
            <FrontpageWidgetHeading style={{marginTop:"20px"}} brand="PWG">
              Quick Tools
            </FrontpageWidgetHeading>
            <Panel>
              <strong>Message</strong><br />
              <Select style={{width:"100%", marginTop:"10px"}}>
                <Option>Select recipients</Option>
              </Select>
              <FormControl style={{marginLeft:"5px"}}>
                <Label style={{width:"100px"}}>Message</Label>
                <textarea style={{width:"300px", border:"1px solid #cdcdcd", borderRadius:"7px"}}/>
              </FormControl>
              <div style={{display:"flex", justifyContent:"flex-end", marginTop:"10px"}}>
                <Button style={{marginRight:"10px"}}><Envelope size={16} /> Send Email</Button>
                <Button><MobileAlt size={16} /> Send SMS</Button>
              </div>
            </Panel>
            <Panel style={{marginTop:"20px"}}>
              <strong>Postcode lookup</strong>
              <FormControl>
                <FormInput />
                <Button>Lookup</Button>
              </FormControl>
            </Panel>
            <FrontpageWidgetHeading style={{marginTop:"20px"}} brand="PWG">
              Summary
            </FrontpageWidgetHeading>
            <div>
              <InfoContainer>
                <Label>Web Enquiries Today</Label>
                <ContentContainer style={{flex:"flex-start", alignItems:"center"}}><Bar number={3} emphasis /> 3</ContentContainer>
              </InfoContainer>
              <InfoContainer style={{marginTop:"0px"}}>
                <Label>Web Enquiries Yesterday</Label>
                <ContentContainer style={{flex:"flex-start", alignItems:"center"}}><Bar number={19} /> 19</ContentContainer>
              </InfoContainer>
              <InfoContainer>
                <Label>Phone Enquiries Today</Label>
                <ContentContainer style={{flex:"flex-start", alignItems:"center"}}><Bar number={1} emphasis /> 1</ContentContainer>
              </InfoContainer>
              <InfoContainer style={{marginTop:"0px"}}>
                <Label>Phone Enquiries Yesterday</Label>
                <ContentContainer style={{flex:"flex-start", alignItems:"center"}}><Bar number={5} /> 5</ContentContainer>
              </InfoContainer>
            </div>

          </Column>
          <Column width="66%">
            <FrontpageWidgetHeading brand="PWG">
              Log new Timber Windows Enquiry
            </FrontpageWidgetHeading>
            <Panel>
              <ContentContainer style={{alignItems:"flex-start"}}>
                <div style={{width:"400px"}}>
                  <FormControl>
                    <Label>Name</Label>
                    <FormInput />
                  </FormControl>

                  <FormControl>
                    <Label>Postcode</Label>
                    <FormInput />
                  </FormControl>

                  <FormControl>
                    <Label>Email</Label>
                    <FormInput />
                  </FormControl>

                  <FormControl>
                    <Label>Phone number</Label>
                    <FormInput />
                  </FormControl>
                  </div>
                <div>
                  <FormControl>
                    <Label>I am a</Label>
                    <Select>
                      <Option>Select...</Option>
                      <Option>Householder</Option>
                      <Option>Developer</Option>
                      <Option>Architect</Option>
                      <Option>Other</Option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <Label>Interested in...</Label>
                    <Select>
                      <Option>Select...</Option>
                      <Option>Brochure request</Option>
                      <Option>Brochure download</Option>
                      <Option>Magazine download</Option>
                      <Option>Timber windows</Option>
                      <Option>Timber doors</Option>
                      <Option>Timber windows and doors</Option>
                      <Option>Timber sash windows</Option>
                      <Option>Timber sash windows and doors</Option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <Label>Source</Label>
                    <Select>
                      <Option>Select...</Option>
                      <Option>JL</Option>
                      <Option>Waitrose</Option>
                      <Option>BMJ</Option>
                      <Option>Exhibition</Option>
                      <Option>Timber Windows website</Option>
                      <Option>Press</Option>
                    </Select>
                  </FormControl>
                  <div style={{display:"flex", justifyContent:"flex-end"}}>
                    <Button style={{marginTop:"20px"}}>Log new TW enquiry</Button>
                  </div>
                </div>
              </ContentContainer>
            </Panel>

            <FrontpageWidgetHeading brand="PWG" style={{marginTop:"32px"}}>
              Enquiries (1)
            </FrontpageWidgetHeading>
            <Table>
              <tbody>
                <HeaderRow>
                  <HeaderCell style={{width:"20px"}} />
                  <HeaderCell>Name</HeaderCell>
                  <HeaderCell>Type</HeaderCell>
                  <HeaderCell>Postcode</HeaderCell>
                  <HeaderCell>Route</HeaderCell>
                  <HeaderCell>Date</HeaderCell>
                  <HeaderCell>Actioned</HeaderCell>
                  <HeaderCell>Status</HeaderCell>
                  <HeaderCell />
                </HeaderRow>
                <Row>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>Molly Kramer</Cell>
                  <Cell>-</Cell>
                  <Cell>AA11 0AA</Cell>
                  <Cell><Globe size={16} /></Cell>
                  <Cell>Tue 15 Jan 19 09:30</Cell>
                  <Cell>-</Cell>
                  <Cell>-</Cell>
                  <Cell><Button>Open</Button></Cell>
                </Row>
              </tbody>
            </Table>
            <div style={{textAlign:"center", marginTop:"5px"}}>
              <ExternalLinkButton>Show all</ExternalLinkButton>
            </div>
            <FrontpageWidgetHeading brand="PWG" style={{marginTop:"32px"}}>
              Brochure requests (3)
            </FrontpageWidgetHeading>
            <Table>
              <tbody>
                <HeaderRow>
                  <HeaderCell style={{width:"20px"}} />
                  <HeaderCell>Received</HeaderCell>
                  <HeaderCell>Customer name</HeaderCell>
                  <HeaderCell>Postcode</HeaderCell>
                  <HeaderCell>Source</HeaderCell>
                  <HeaderCell>Status</HeaderCell>
                  <HeaderCell>Dispatched</HeaderCell>
                </HeaderRow>
                <Row>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>Tue 15th Jan 2019</Cell>
                  <Cell>Molly Kramer</Cell>
                  <Cell>AA11 0AA</Cell>
                  <Cell><Globe size={16} /></Cell>
                  <Cell>New</Cell>
                  <Cell><Button>Mark as dispatched</Button></Cell>
                </Row>
                <Row>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>Tue 15th Jan 2019</Cell>
                  <Cell>Robert Bowman</Cell>
                  <Cell>AA11 0AA</Cell>
                  <Cell><Globe size={16} /></Cell>
                  <Cell>New</Cell>
                  <Cell><Button>Mark as dispatched</Button></Cell>
                </Row>
                <Row>
                  <Cell><img src={twBadge} /></Cell>
                  <Cell>Tue 15th Jan 2019</Cell>
                  <Cell>Rebecca Stuart</Cell>
                  <Cell>AA11 0AA</Cell>
                  <Cell><Globe size={16} /></Cell>
                  <Cell>New</Cell>
                  <Cell><Button>Mark as dispatched</Button></Cell>
                </Row>
              </tbody>
            </Table>
            <div style={{textAlign:"center", marginTop:"5px"}}>
              <ExternalLinkButton>Show all</ExternalLinkButton>
            </div>
          </Column>

        </ContentContainer>
        <ContentContainer brand="PWG" style={{marginTop:"20px", marginBottom: "20px"}}>
          <Column width="100%" firstColumn>
            <div style={{textAlign:"center", marginBottom:"20px", color:"red"}}>(Placeholder links to other apps below)</div>
            <AppToolbar profile={this.props.sales}/>
          </Column>
        </ContentContainer>
      </AppContent>
    )
  }
};

export default SalesProfile;



