import React, { Component } from "react";
import { Phone } from "styled-icons/fa-solid";

import Header from "../shared/header/Header";
import HeaderButtonLink from "../shared/header/HeaderButtonLink";

const daleMenuLinks = [
  {
    to: "/aperture",
    name: "Home"
  },{
    to: "/aperture/quotes",
    name: "Quotes"
  },{
    to: "/aperture/orders",
    name: "Orders"
  },{
    to: "/aperture/support",
    name: "Support"
  },{
    to: "/aperture/settings",
    name: "Settings"
  },
];

const twMenuLinks = [
  {
    to: "/aperture",
    name: "Home"
  },{
    to: "/aperture/crm",
    name: "CRM"
  },{
    to: "/aperture/leads",
    name: "Leads"
  },{
    to: "/aperture/quotes",
    name: "Quotes"
  },{
    to: "/aperture/orders",
    name: "Orders"
  },{
    to: "/aperture/partsShop",
    name: "Parts Shop"
  },{
    to: "/aperture/support",
    name: "Support"
  },{
    to: "/aperture/salesforce",
    name: "Salesforce"
  },{
    to: "/aperture/settings",
    name: "Settings"
  },
];

class ApertureHeader extends Component {
  render() {
    return (
      <Header
        brand={this.props.brand}
        links={this.props.brand === 'TW' ? twMenuLinks : daleMenuLinks}
        isAuthenticated={this.props.isAuthenticated}
        logout={this.props.logout}
      >
        <HeaderButtonLink brand={this.props.brand} to='/aperture'>
          <Phone size={16} /> Request Call Back
        </HeaderButtonLink>
      </Header>
    );
  }
}

export default ApertureHeader;
