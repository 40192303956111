import React, { Component } from "react";

import AppContent from "../shared/layout/AppContent";
import ApertureHeading from "../shared/aperture/ApertureHeading";

import InlineForm from "../shared/form-controls/InlineForm";
import FormControl from "../shared/form-controls/FormControl";
import FormInput from "../shared/form-controls/FormInput";
import FormText from "../shared/form-controls/FormText";
import Select from "../shared/form-controls/Select";
import Option from "../shared/form-controls/Option";

import Button from "../shared/Button";
import Panel from "../shared/layout/Panel";

import OrdersTable from "./OrdersTable";

class ApertureOrders extends Component {
  render() {
    return (
      <AppContent>
        <ApertureHeading centre>
          Orders
        </ApertureHeading>
        <Panel>
          <InlineForm>
            <FormControl>
              <FormText width='70px'>Filter</FormText>
              <Select>
                <Option>Order ref</Option>
                <Option>Your ref</Option>
                <Option>Date</Option>
                <Option>Financial status</Option>
                <Option>Logistics status</Option>
              </Select>
              <FormInput style={{marginLeft:"20px"}}/>
            </FormControl>
          </InlineForm>
        </Panel>
        <OrdersTable />
        <div style={{display:"flex", justifyContent: "center", width:"100%", marginTop:"10px"}}>
          <Button>Load more</Button>
        </div>
      </AppContent>
    )
  }
};

export default ApertureOrders;
