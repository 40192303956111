import styled from "styled-components";
import {theme} from "../../constants";

const CardHeading = styled.div`
  margin: 0px;
  padding: 7px 10px 7px 10px;
  text-align: center;
  font-family: ${props => props.brand === 'TW' ? theme.fonts.heading : theme.fonts.dashboard};
  font-size: 1.2em;
  color: white;
  background-color: ${props => props.brand === 'TW' ? theme.colors.tw : (props.brand === 'PWG' ? theme.colors.pwg : theme.colors.dale)};}
`;

export default CardHeading;
