import React, { Component } from "react";
import { CaretDown, CaretRight, Phone, Envelope } from "styled-icons/fa-solid";

import Table from "../shared/table/Table";
import Row from "../shared/table/Row";
import Cell from "../shared/table/Cell";
import HeaderCell from "../shared/table/HeaderCell";
import HeaderRow from "../shared/table/HeaderRow";
import Button from "../shared/Button";

class QuotesTable extends Component {
  render() {
    return (
      <Table>
        <thead>
        <HeaderRow>
          <HeaderCell />
          <HeaderCell scope="col">Ref</HeaderCell>
          <HeaderCell scope="col">Surname</HeaderCell>
          <HeaderCell scope="col">Telephone</HeaderCell>
          <HeaderCell scope="col">Email</HeaderCell>
          <HeaderCell scope="col">Billing Address</HeaderCell>
          <HeaderCell scope="col">Showroom</HeaderCell>
          <HeaderCell scope="col">Salesperson</HeaderCell>
          <HeaderCell scope="col">Date</HeaderCell>
          <HeaderCell />
        </HeaderRow>
        </thead>
        <tbody>
        <Row>
          <Cell><CaretRight size={16} /></Cell>
          <Cell>1234</Cell>
          <Cell>Pierce</Cell>
          <Cell>
            <Phone size={16} style={{color: "#7a8e8d", marginRight:"10px"}}/> 01608 555234
          </Cell>
          <Cell>
            <Envelope size={16} style={{color: "#7a8e8d", marginRight:"10px"}}/> thomas.pierce@iamcustomer.com
          </Cell>
          <Cell>
            12 Windowless Villas, Somewhere, Somecounty, AA11 0AA
          </Cell>
          <Cell>Cirencester</Cell>
          <Cell>Jenson Interceptor</Cell>
          <Cell>07/01/2019</Cell>
          <Cell><Button>View Quotes (2)</Button></Cell>
        </Row>
        <Row>
          <Cell><CaretRight size={16} /></Cell>
          <Cell>2354</Cell>
          <Cell>Hines</Cell>
          <Cell>
            <Phone size={16} style={{color: "#7a8e8d", marginRight:"10px"}}/> 01234 567892
          </Cell>
          <Cell>
            <Envelope size={16} style={{color: "#7a8e8d", marginRight:"10px"}}/> frank.hines@iamcustomer.com
          </Cell>
          <Cell>
            16 Windowless Villas, Somewhere, Somecounty, AA11 0AA
          </Cell>
          <Cell>Cirencester</Cell>
          <Cell>Jenson Interceptor</Cell>
          <Cell>04/01/2019</Cell>
          <Cell><Button>View Quote</Button></Cell>
        </Row>
        <Row>
          <Cell><CaretRight size={16} /></Cell>
          <Cell>2354</Cell>
          <Cell>Bowman</Cell>
          <Cell>
            <Phone size={16} style={{color: "#7a8e8d", marginRight:"10px"}}/> 01234 567892
          </Cell>
          <Cell>
            <Envelope size={16} style={{color: "#7a8e8d", marginRight:"10px"}}/> robert.bowman@iamcustomer.com
          </Cell>
          <Cell>
            26 Windowless Villas, Somewhere, Somecounty, AA11 0AB
          </Cell>
          <Cell>Cirencester</Cell>
          <Cell>Jenson Interceptor</Cell>
          <Cell>03/01/2019</Cell>
          <Cell><Button>View Quote</Button></Cell>
        </Row>
        <Row>
          <Cell><CaretRight size={16} /></Cell>
          <Cell>2354</Cell>
          <Cell>Stuart</Cell>
          <Cell>
            <Phone size={16} style={{color: "#7a8e8d", marginRight:"10px"}}/> 01234 567892
          </Cell>
          <Cell>
            <Envelope size={16} style={{color: "#7a8e8d", marginRight:"10px"}}/> rebecca.stuart@iamcustomer.com
          </Cell>
          <Cell>
            35 Windowless Villas, Somewhere, Somecounty, AA11 0AB
          </Cell>
          <Cell>Cirencester</Cell>
          <Cell>Jenson Interceptor</Cell>
          <Cell>03/01/2019</Cell>
          <Cell><Button>View Quotes (3)</Button></Cell>
        </Row>
        </tbody>
      </Table>
    )
  }
};

export default QuotesTable;
